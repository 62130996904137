import React, {useState, useEffect} from 'react';
import ManageAProposTitle from './aProposComponents/ManageAProposTitle';
import ManageAProposPara1 from './aProposComponents/ManageAProposPara1';
import ManageAProposPara2 from './aProposComponents/ManageAProposPara2';
import ManageAProposPostsciptum from './aProposComponents/ManageAProposPostsciptum';
import DisplayImage from './aProposComponents/DisplayImage';
import UpdateOrDeletePara from './aProposComponents/UpdateOrDeletePara';
import PageHeader from './PageHeader';
import Footer from './Footer';
import ArrowUp from './ArrowUp';
import {Link} from 'react-router-dom';
import getAProposData from './request/aPropos/getAProposData';
import setAProposNewImage from './request/aPropos/setAProposNewImage';
import restoreOldAProposPara from './request/aPropos/restoreOldAProposPara';
import '../style/aproposStyle.css';

export default function APropos(props) {
  const [aProposData, setAProposData] = useState([]);
  const [oldAProposData, setOldAProposData] = useState([]);
  const [newData, setNewData] = useState(null);
  const [markUpdating, setMarkUpdating] = useState({state: false, id: null})
  const [changeImg, setChangeImg] = useState(false)

  const onChangeImagePlace = (e, index) => {
    setNewData({...newData, image_place: e.target.value});
    let newD = aProposData;
    newD[index].image_place = e.target.value;
    setAProposData(newD);
    setMarkUpdating({state: true, id: index});
  }
  const changeParaTitle = (e, index) => {
    setNewData({...newData, title: e.target.value});
    let newD = aProposData;
    newD[index].title = e.target.value;
    setAProposData(newD);
    setMarkUpdating({state: true, id: index});
  }
  const changeParaPostscriptum = (e, index) => {
    setNewData({...newData, postscriptum: e.target.value});
    let newD = aProposData;
    newD[index].postscriptum = e.target.value;
    setAProposData(newD);
    setMarkUpdating({state: true, id: index});
  }
  const changeParaText_1 = (e, index) => {
    setNewData({...newData, text_1: e.target.value});
    let newD = aProposData;
    newD[index].text_1 = e.target.value;
    setAProposData(newD);
    setMarkUpdating({state: true, id: index});
  }
  const changeParaText_2 = (e, index) => {
    setNewData({...newData, text_2: e.target.value});
    let newD = aProposData;
    newD[index].text_2 = e.target.value;
    setAProposData(newD);
    setMarkUpdating({state: true, id: index});
  }
  const changeParaImageBgColor = (e, index) => {
    setNewData({...newData, image_bg_color: e.target.value});
    let newD = aProposData;
    newD[index].image_bg_color = e.target.value;
    setAProposData(newD);
    setMarkUpdating({state: true, id: index});
  }
  const onChangeAProposImage = (e, index) => {
    setAProposNewImage(
        e.target.files[0],
        props.admin,
        props.setAdmin,
        setAProposData,
        aProposData[index],
      );
  }
  const restaureAProposPara = (index) => {
    restoreOldAProposPara(
      props.admin,
      props.setAdmin,
      setOldAProposData,
      setAProposData,
      oldAProposData[index],
    )
  }     
  
  useEffect(() => {
    if (!props.admin.mode) {
      window.scrollTo(0,0);
    }
    
    if (aProposData && aProposData.length === 0) {
      getAProposData(props.admin, setAProposData, setOldAProposData);
    }

    return;
  }, [aProposData])

  return (
    <div
      className="col_flex_aro dispPage noselect"
      id="aProposPageEffect"
      style={{width: '100vw', paddingTop: '150px'}}>
      <PageHeader
        admin={props.admin}
        setAdmin={props.setAdmin}
        changeImg={changeImg}
        setChangeImg={setChangeImg}
        setData={setAProposData}
        setFormule={false}
        setNotice={false}
        imageName="img_apropos_1_1500x540.png"
        imageId="aProposTopImg"
        updateOrder="AProposTopImg"
        pageTitleDisplay={true}
        pageTitle="À propos" />
        {aProposData && aProposData.length === 0 && (
          <div className="row_flex_aro">
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
          </div>
        )}




        {!props.admin.mode
          && aProposData
          && aProposData.length > 0
          && aProposData.map((apr, index) => (
          <div
            key={'apropos' + index}
            className="col_flex_aro aProposContainer" >
            {apr.title !== '' && (
              <div className="row_flex_aro apropos_para_title">
                <h3 className="apropos_title">{apr.title}</h3>
              </div>
            )}
            <div className={
              window.screen.width > 950
                ? "row_flex_aro paraPlusImgContainer"
                : "col_flex_aro paraPlusImgContainer"
              }>
              {(apr.image_place === 'left' || window.screen.width < 950) && (
                <div style={{position: 'relative'}}>
                  <div
                    className="bgImage"
                    style={{
                      left: window.screen.width < 450 ? '-10px' : '-25px',
                      top: window.screen.width < 450 ? '-10px' : '-25px',
                      '--articleImgBg': aProposData[index].image_bg_color,
                      transform: 
                      (props.admin.mode
                        && aProposData[index].image_place === 'right')
                          ? 'translateX(70px)'
                          : 'translateX(0)',
                      }}>
                  </div>
                  <img
                    className="articleImg"
                    src={process.env.REACT_APP_API_IMAGES + apr.image}
                    alt={"A propos, article " + index} />
                </div>
              )}
              {apr.text_1 !== "" && (
                <div className="row_flex_aro">
                  <p className="paraStyle articleText1">
                    <span style={{paddingLeft: '30px'}}></span>
                    {apr.text_1}
                  </p>
                </div>
              )}


            {(apr.image_place === 'right' && window.screen.width > 950) && (
              <div style={{position: 'relative'}}>
                <div
                  className="bgImage"
                  style={{
                    right: window.screen.width < 450 ? '-10px' : '-25px',
                    top: window.screen.width < 450 ? '-10px' : '-25px',
                    '--articleImgBg': aProposData[index].image_bg_color,
                    transform: 
                    (props.admin.mode
                      && aProposData[index].image_place === 'right')
                        ? 'translateX(70px)'
                        : 'translateX(0)',
                    }}>
                </div>
                <div>
                  <img
                    className="articleImg"
                    src={process.env.REACT_APP_API_IMAGES + apr.image}
                    alt={"A propos, article " + index} />
                </div>
              </div>
            )}
          </div>


            {apr.text_2 !== '' && (
              <div className="row_flex_aro">
                <p className="paraStyle articleText2">
                  <span style={{paddingLeft: '30px'}}></span>
                  {apr.text_2}
                </p>
              </div>
            )}
            {apr.text_2 !== '' && (
              <div className="row_flex_aro">
                <p className="paraStyle articlePostscriptum">
                  <span style={{paddingLeft: '30px'}}></span>
                  {apr.postscriptum}
                </p>
              </div>
            )}
            <hr className="hrStyle" style={{marginTop: '70px'}}></hr>
          </div>
        ))}
        {props.admin.mode
          && aProposData
          && aProposData.length > 0
          && aProposData.map((apr, index) => (
          <div key={'apropos' + index}>
            <ManageAProposTitle
              index={index}
              newData={newData}
              setNewData={setNewData}
              aProposData={aProposData}
              setAProposData={setAProposData}
              changeParaTitle={changeParaTitle} />
            {apr.image_place === 'left' && (
              <div className="row_flex_aro" style={{width: '90vw'}}>
                <DisplayImage
                  index={index}
                  admin={props.admin}
                  newData={newData}
                  setNewData={setNewData}
                  aProposData={aProposData}
                  setAProposData={setAProposData}
                  onChangeImagePlace={onChangeImagePlace}
                  onChangeAProposImage={onChangeAProposImage}
                  changeParaImageBgColor={changeParaImageBgColor} />
                <ManageAProposPara1
                  index={index}
                  newData={newData}
                  setNewData={setNewData}
                  aProposData={aProposData}
                  setAProposData={setAProposData}
                  changeParaText_1={changeParaText_1} />
              </div>
            )}
            {apr.image_place === 'right' && (
              <div className="row_flex_aro" style={{width: '90vw'}}>
                <ManageAProposPara1
                  index={index}
                  newData={newData}
                  setNewData={setNewData}
                  aProposData={aProposData}
                  setAProposData={setAProposData}
                  changeParaText_1={changeParaText_1} />
                <DisplayImage
                  index={index}
                  admin={props.admin}
                  newData={newData}
                  setNewData={setNewData}
                  aProposData={aProposData}
                  setAProposData={setAProposData}
                  onChangeImagePlace={onChangeImagePlace}
                  onChangeAProposImage={onChangeAProposImage}
                  changeParaImageBgColor={changeParaImageBgColor} />
              </div>
            )}
            <ManageAProposPara2
              index={index}
              newData={newData}
              setNewData={setNewData}
              aProposData={aProposData}
              setAProposData={setAProposData}
              changeParaText_2={changeParaText_2} />
            <ManageAProposPostsciptum
              index={index}
              newData={newData}
              setNewData={setNewData}
              aProposData={aProposData}
              setAProposData={setAProposData}
              changeParaPostscriptum={changeParaPostscriptum} />
            <UpdateOrDeletePara
              index={index}
              admin={props.admin}
              setAdmin={props.setAdmin}
              aProposData={aProposData}
              setAProposData={setAProposData}
              setOldAProposData={setOldAProposData}
              markUpdating={markUpdating}
              setMarkUpdating={setMarkUpdating} />
            <hr className="hrStyle" style={{marginTop: '70px'}}></hr>
          </div>
        ))}
        {props.admin.mode
          && oldAProposData
          && oldAProposData.length > 0
          && oldAProposData.map((apr, index) => (
          <div
            key={'oldArticle' + index}
            className="col_flex_aro oldArticleContainer">
            <button
              className="butStyle_1 restorBut"
              style={{background: 'yellow'}}
              onClick={() => restaureAProposPara(index)}>
                Restaurer
            </button>
            <img
              width="250" height="180"
              src={process.env.REACT_APP_API_IMAGES + apr.image}
              alt={'ancien article num' + index} />
            <div className="col_flex_aro apropos_para_title">
              <h3><i>{apr.title}</i></h3>
            </div>
            <div
              className="col_flex_aro apropos_para_text"
              style={
                apr.image_place === 'left'
                  ? {marginLeft: '50px'}
                  : {marginRight: '50px'}} >
              <p><span style={{paddingLeft: '30px'}}></span>
                {apr.text_1}
              </p>
            </div>
            <div className="col_flex_aro apropos_para_text" style={{marginLeft: '50px'}}>
              <p><span style={{paddingLeft: '30px'}}></span>
                {apr.postscriptum}
              </p>
            </div>
          </div>
        ))}
      <div
        className="row_flex_aro"
        style={{position: 'relative', marginBottom: '20px'}}>
        <Link
          to={'/Contact'}
          style={{textDecoration: 'none'}}
          onClick={() => props.setMenu({...props.menu, menu2: 'Contact'})}>
          <img
            className="clikableImage"
            src={process.env.REACT_APP_API_IMAGES + '/contactImage.png'}
            alt="Lien vers la page contact"/>
          <div
            className="textOnImage"
            id="markContact">Contact</div>
        </Link>
      </div>
      <hr className="hrStyle"></hr>
      <Footer />
      <ArrowUp />
    </div>
  )
}
