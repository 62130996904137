import React, {useState, useEffect} from 'react';
import getLogs from '../request/getLogs';
import '../../style/logsStyle.css';

export default function DisplayLogs(props) {
    const [logs, setLogs] = useState([]);
    const [offset, setOffset] = useState(20);

    useEffect(() => {
        getLogs(props.admin, props.setAdmin, setLogs, offset);

        return;
    }, [])
    return (
        <div className="modalContainer row_flex_aro dispModal">
            <div
                className="modalContent col_flex_aro"
                style={{
                    maxHeight: '70vh',
                    paddingTop: '13vh',
                    overflow: 'auto',
                    }}>
                <div style={{marginRight: '40vw'}}>
                    <button
                        type="button"
                        className="logChoice noselect"
                        onClick={() =>{
                            let ofst = offset > 20 ? offset - 20 : offset;
                            setOffset(ofst);
                            getLogs(props.admin, props.setAdmin, setLogs, ofst);
                        }}>- 20</button>
                    <input
                        type="number"
                        step="20"
                        min="20"
                        className="noselect"
                        style={{
                            maxWidth: '60px',
                            fontSize: '22px',
                            textAlign: 'center',
                            background: 'grey',
                        }}
                        value={offset}
                        onChange={(e) => setOffset(e.target.value)} />
                        <button
                            type="button"
                            className="noselect"
                            style={{background: 'lightgreen'}}
                            onClick={() =>
                                getLogs(props.admin, props.setAdmin, setLogs, offset)
                            }>OK</button>
                    <button
                        type="button"
                        className="logChoice noselect"
                        onClick={() =>{
                            let ofst = offset + 20
                            setOffset(ofst);
                            getLogs(props.admin, props.setAdmin, setLogs, ofst);
                        }}>+ 20</button>
                </div>
                {logs && logs.map((lg, index) => (
                    <div
                        key={'log' + index}
                        className="row_flex_aro logLine" >
                        <div className="dispLogIndex">
                            <span>{lg.id}</span>
                        </div>
                        <span style={{color: 'white'}}> | </span>
                        <div className="dispLogDate">
                            <span>{lg.created_at}</span>
                        </div>
                        <span style={{color: 'white'}}> | </span>
                        <div className="dispLogPage">
                            <span>{lg.page}</span>
                        </div>
                        <span style={{color: 'white'}}> | </span>
                        <div className="dispLogNb">
                            <span>{lg.nb}</span>
                        </div>
                        <span style={{color: 'white'}}> | </span>
                        <div className="dispLogIp">
                            <span>{lg.ip}</span>
                        </div>
                        <span style={{color: 'white'}}> | </span>
                        <div className="dispLogIpName">
                            <span>{lg.ip_name}</span>
                        </div>
                        <span style={{color: 'white'}}> | </span>
                        <div className="dispLogEmail">
                            <span>{lg.email}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
