import React from 'react';

export default function PolitiqueConfidentialite(props) {
  return (
    <div className="col_flex_aro legalContainer" style={{width: '90vw'}}>
        <div className="row_flex_aro">
            <h2 className="legalTitle">Politique de confidentialité</h2>
            <div>
                <button
                  className="closeButDiv"
                  onClick={() => props.setDispPolConf(false)}
                  style={{
                      position: 'fixed',
                      top: '100px',
                      right: '5vw',
                      transform: 'scale(0.7)',
                  }}>X</button>
            </div>
        </div>
        <div className="legalPara">
          <h3>1. Introduction</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Bel Événement s'engage à protéger la vie privée de ses clients et à 
              traiter leurs données personnelles conformément aux réglementations 
              en vigueur, notamment le Règlement Général sur la Protection des 
              Données (RGPD).
          </p>
          <h3>2. Informations collectées</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Nous collectons des informations personnelles lorsque vous :
          </p>
          <ul>
            <li>Remplissez un formulaire de contact</li>
            <li>Demandez un devis ou des informations sur nos services</li>
            <li>Participez à nos événements</li>
          </ul>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Les types d'informations que nous pouvons collecter incluent :
          </p>
          <ul>
            <li>Nom et prénom</li>
            <li>Adresse email</li>
            <li>Numéro de téléphone</li>
            <li>Informations de facturation</li>
            <li>Détails sur les événements souhaités</li>
          </ul>
          <h3>3. Utilisation des données</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Nous utilisons vos données personnelles pour :
          </p>
          <ul>
            <li>Gérer vos demandes et devis</li>
            <li>Personnaliser nos services selon vos besoins</li>
            <li>Communiquer avec vous concernant vos événements</li>
            <li>Vous envoyer des informations sur nos services, promotions et événements</li>
          </ul>
          <h3>4. Partage des données</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Vos données personnelles ne seront pas vendues, échangées ou transférées à des 
              tiers sans votre consentement, sauf si cela est nécessaire pour :
          </p>
          <ul>
            <li>La réalisation de nos services (ex. : prestataires externes pour l'événement)</li>
            <li>Le respect de la loi ou d'une obligation légale</li>
          </ul>
          <h3>5. Sécurité des données</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger 
              vos données personnelles contre toute perte, accès non autorisé, divulgation ou 
              modification.
          </p>
          <h3>6. Durée de conservation</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Nous conservons vos données personnelles aussi longtemps que nécessaire pour atteindre 
              les finalités pour lesquelles elles ont été collectées ou pour respecter nos obligations 
              légales.
          </p>
          <h3>7. Vos droits</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Conformément à la réglementation en vigueur, vous disposez des droits suivants :
          </p>
          <ul>
            <li>Droit d'accès : vous pouvez demander une copie de vos données personnelles.</li>
            <li>Droit de rectification : vous pouvez demander la correction de vos données inexactes.</li>
            <li>Droit à l'effacement : vous pouvez demander la suppression de vos données.</li>
            <li>Droit de limitation du traitement : vous pouvez demander une limitation du traitement 
              de vos données.</li>
            <li>Droit d'opposition : vous pouvez vous opposer au traitement de vos données à des fins 
              de marketing.</li>
            <li>Droit à la portabilité : vous pouvez demander la transmission de vos données à un autre 
              responsable de traitement.</li>
          </ul>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Pour exercer ces droits, veuillez nous contacter à 
              <span style={{color: 'blue'}}> br.belevenement@hotmail.com</span> .
          </p>
          <h3>8. Cookies</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Notre site peut utiliser des cookies pour améliorer votre expérience utilisateur. 
              Vous pouvez choisir d'accepter ou de refuser les cookies via votre navigateur. 
              Notez que le refus de cookies peut affecter certaines fonctionnalités de notre site.
          </p>
          <h3>9. Modifications de la politique</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Nous nous réservons le droit de modifier cette politique de confidentialité à tout 
              moment. Les changements seront publiés sur notre site web avec une date de mise à jour.
          </p>
          <h3>10. Contact</h3>
          <p>
              <span style={{marginLeft: '30px'}}></span>
              Pour toute question concernant cette politique de confidentialité ou le traitement de 
              vos données personnelles, veuillez nous contacter à :
          </p>
          <br></br>
          <p style={{margin: '5px'}}>
            Bel Événement
          </p>
          <p style={{margin: '5px'}}>
            883 route d’Olmet - 34700 Lodève
          </p>
          <p style={{margin: '5px'}}>
            07 82 10 26 06
          </p>
          <p style={{margin: '5px', color: 'blue'}}>
            br.belevenement@hotmail.com
          </p>


        </div>
        <div style={{marginBottom: '200px'}}></div>
    </div>
  )
}
