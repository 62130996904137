import React from 'react';
import setFormuleContent from '../request/services/setFormuleContent';
import updateFormuleImage from '../request/services/updateFormuleImage';

export default function FormuleAdminForm(props) {
  return (
    <div className="modalCardContainer row_flex_aro">
      <div
        className="formuleContent modalCardContent"
        style={{
            minHeight: '500px',
            background: props.editFormule.formule.image_bg,
          }}>
        <div className="row_flex_aro" style={{width: '100%'}}>
          <div className="col_flex_aro">
            <img
              className="formuleImage"
              src={process.env.REACT_APP_API_IMAGES + props.editFormule.formule.image}
              alt={'formule pourmodification'} />
              <div style={{textAlign: 'center'}}>
                <p style={{padding: '0', margin: '0'}}>De quel coté l'image?</p>
                <div className="row_flex_aro">
                  <div style={{margin: '0 20px'}}>
                    <label htmlFor="formuleImgLeft">A gauche</label>
                    <input
                      type="radio"
                      id="formuleImgLeft"
                      name="formuleImgPlace"
                      value="left"
                      onChange={(e) => {
                        let newFrm = props.editFormule.formule;
                        newFrm.image_place = e.target.value;
                        props.setEditFormule({...props.editFormule, formule: newFrm});
                      }}
                      checked={props.editFormule.formule.image_place === 'left'} />
                  </div>
                  <div style={{margin: '0 20px'}}>
                    <label htmlFor="formuleImgRight">A droite</label>
                    <input
                      type="radio"
                      id="formuleImgRight"
                      name="formuleImgPlace"
                      value="right"
                      onChange={(e) => {
                        let newFrm = props.editFormule.formule;
                        newFrm.image_place = e.target.value;
                        props.setEditFormule({...props.editFormule, formule: newFrm});
                      }}
                      checked={props.editFormule.formule.image_place === 'right'} />
                    </div>
                </div>
                <hr></hr>
                <div className="row_flex_aro">
                  <div>
                    <p style={{padding: '0', margin: '0'}}>Couleur de fond</p>
                    <input
                      type="color"
                      onChange={(e) => {
                        let newFrm = props.editFormule.formule;
                        newFrm.image_bg = e.target.value;
                        props.setEditFormule({...props.editFormule, formule: newFrm});
                      }}
                      value={props.editFormule.formule.image_bg} />
                    </div>
                  <div>
                    <p style={{padding: '0', margin: '0'}}>Couleur du texte</p>
                    <input
                      type="color"
                      onChange={(e) => {
                        let newFrm = props.editFormule.formule;
                        newFrm.image_text_color = e.target.value;
                        props.setEditFormule({...props.editFormule, formule: newFrm});
                      }}
                      value={props.editFormule.formule.image_text_color} />
                    </div>
                </div>
                <hr></hr>
                <div>
                  <p style={{padding: '0', margin: '0'}}>Changer l'image? (300x / 300px)</p>
                  <input
                        type="file"
                        onChange={(e) => {
                          updateFormuleImage(
                            'updateVoyagesFormuleImage',
                            e.target.files[0],
                            props.admin,
                            props.setAdmin,
                            props.setFormule,
                            props.editFormule.formule,
                            props.setEditFormule,
                        )}} />
                </div>
              </div>
          </div>
          <div className="col_flex_aro servVoyFormTitle" style={{minHeight: '500px'}}>
            <div style={{textAlign: 'center'}}>
              <span
                  style={{
                      color: props.editFormule.formule.title.length < 26 ? 'blue' : 'red',
                      fontSize: '16px',
                      }}>{props.editFormule.formule.title.length} / 30 caractéres</span>
              <textarea
                className="servVoyFormTitle editFormuleZone"
                rows="2"
                cols="20"
                onChange={(e) => {
                  if (e.target.value.length <= 26) {
                    let newFrm = props.editFormule.formule;
                    newFrm.title = e.target.value;
                    props.setEditFormule({...props.editFormule, formule: newFrm});
                  }
                }}
                value={props.editFormule.formule.title}></textarea>
            </div>
            <div style={{textAlign: 'center'}}>
              <span
                  style={{
                      color: props.editFormule.formule.text_1.length < 256 ? 'blue' : 'red',
                      fontSize: '16px',
                      }}>{props.editFormule.formule.text_1.length} / 256 caractéres</span>
              <textarea
                className="servVoyFormText1 editFormuleZone"
                rows="4"
                cols="20"
                onChange={(e) => {
                  if (e.target.value.length <= 256) {
                    let newFrm = props.editFormule.formule;
                    newFrm.text_1 = e.target.value;
                    props.setEditFormule({...props.editFormule, formule: newFrm});
                  }
                }}
                value={props.editFormule.formule.text_1}></textarea>
            </div>
            <div style={{textAlign: 'center'}}>
              <span
                  style={{
                      color: props.editFormule.formule.text_2.length < 65 ? 'blue' : 'red',
                      fontSize: '16px',
                      }}>{props.editFormule.formule.text_2.length} / 65 caractéres</span>
              <textarea
                className="servVoyFormText2 editFormuleZone"
                rows="4"
                cols="20"
                onChange={(e) => {
                  if (e.target.value.length <= 65) {
                    let newFrm = props.editFormule.formule;
                    newFrm.text_2 = e.target.value;
                    props.setEditFormule({...props.editFormule, formule: newFrm});
                  }
                }}
                value={props.editFormule.formule.text_2}></textarea>
            </div>
              <div className="col_flex_aro">
                <button
                  className="butStyle_1"
                  onClick={() => props.setEditFormule({...props.editFormule, state: false})}
                  style={{background: 'orange', margin: '6px'}}>BACK</button>
                <button
                  className="butStyle_1"
                  onClick={() => {
                    setFormuleContent(
                      'updateVoyagesFormules',
                      props.admin,
                      props.setAdmin,
                      props.editFormule,
                      props.setEditFormule,
                      props.setFormule,
                    )
                  }}
                  style={{background: 'lightgreen', margin: '6px'}}>UPDATE</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}
