import React from 'react';
import '../style/cookies.css';


export default function CookiesMessage(props) {
  return (
    <div className="cookies_container">
        <div className="cookies_content">
            <div className="cookies_title">
                <h2>Cookies</h2>
            </div>
            <hr></hr>
            <div>
                <p style={{textAlign: 'justify', padding: '0 10px'}}>
                    <span style={{marginLeft: '30px'}}></span>
                    Notre site utilise les cookies minimum pour vous offrir une expérience de navigation.
                </p>
            </div>
            <div>
                <p style={{textAlign: 'justify', padding: '0 10px'}}>
                    <span style={{marginLeft: '30px'}}></span>
                    Consultez notre politique de gestion des cookies en bas de page.
                </p>
            </div>
            <hr></hr>
            <div className="row_flex_aro">
                <button 
                    className="validate_cookies"
                    style={{padding: '4px 8px'}}
                    onClick={() => {
                        document.querySelector('.cookies_content').style.animation =
                            'cookies_hide 0.5s ease'
                        setTimeout(() => props.setCookiesDisplay(false), 450);
                    }}>Ok, j'ai compris</button>
            </div>
        </div>
    </div>
  )
}