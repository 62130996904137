import React, {useEffect, useState} from 'react';
import PageHeader from './PageHeader';
import Footer from './Footer';
import ArrowUp from './ArrowUp';
import getGalleryPhoto from './request/gallery/getGalleryPhoto';
import setNewGalleryImage from './request/gallery/setNewGalleryImage';
import deleteGalleryImage from './request/gallery/deleteGalleryImage';
import icons from '../icons';
import '../style/galleryStyle.css';


export default function PlusGalerie(props) {
  const [changeImg, setChangeImg] = useState(false);
  const [photo, setPhoto] = useState([]);
  const [imageWidth, setImageWidth] = useState(330);
  const [galleryFullScreen, setGalleryFullScreen] = useState({disp: false, src: ''});

  useEffect(() => { 
    if (!props.admin.mode) {
      window.scrollTo(0,0);
    }
    if (photo && photo.length === 0) {
      getGalleryPhoto(setPhoto);
    }
    var w = 0;
    if (w === 0 && window.screen.width / 5 > 310) {w = window.screen.width / 5}
    if (w === 0 && window.screen.width / 4 > 310) {w = window.screen.width / 4}
    if (w === 0 && window.screen.width / 3 > 310) {w = window.screen.width / 3}
    if (w === 0 && window.screen.width / 2 > 310) {w = window.screen.width / 2}
    if (w === 0) {w = 300}
    setImageWidth(w);

    return;
  }, [photo])

  return (
    <div className="col_flex_aro dispPage commentPage">
      <PageHeader
          admin={props.admin}
          setAdmin={props.setAdmin}
          changeImg={changeImg}
          setChangeImg={setChangeImg}
          setData={false}
          setFormule={false}
          setNotice={false}
          imageName="img_comment_1_1500x540.png"
          imageId="CommentsTopImg"
          updateOrder="CommentsTopImg"
          pageTitleDisplay={true}
          pageTitle="Galerie" />      
      <div
        className="row_flex_aro dispGalleryPageTitle"
        style={{alignItems: 'end', flexFlow: 'wrap'}}>
        {props.admin.mode && (
          <input
            className=""
            onChange={(e) => setNewGalleryImage(
              e.target.files[0],
              props.admin,
              setPhoto,
            )}
            type="file" />
        )}
      </div>
      {photo && photo.length === 0 && (
          <div className="row_flex_aro">
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
          </div>
        )}
      <div className="gallery_container">
        <div className="row_flex_aro gallery_disposition">
          {photo && photo.length > 0 && photo.map((photo, index) => {
            if (index % 3 === 2) {
              return (
                <div key={'galleryIt' + index} style={{position: 'relative'}}>
                  <button
                    className="fullScreenButton"
                    onClick={() => setGalleryFullScreen({disp: true, src: photo})}>
                      {icons.fullscreen_icon}
                  </button>
                  {props.admin.mode && (
                    <button
                      className="deleteGalleryImageButton"
                      onClick={() => deleteGalleryImage(
                          photo,
                          props.admin,
                          setPhoto,
                        )}>
                        {icons.delete_icon}
                    </button>
                  )}
                  <img
                    className="image_style"
                    src={process.env.REACT_APP_API_GALLERY_IMAGES + photo}
                    width={imageWidth}
                    height={'auto'}
                    alt={'photo groupe 1 index ' + index} />
                </div>
              )}
            if (index % 3 === 1) {
              return (
                <div key={'galleryIt' + index} style={{position: 'relative'}}>
                  <button
                    className="fullScreenButton"
                    onClick={() => setGalleryFullScreen({disp: true, src: photo})}>
                      {icons.fullscreen_icon}
                  </button>
                  {props.admin.mode && (
                    <button
                      className="deleteGalleryImageButton"
                      onClick={() => deleteGalleryImage(
                          photo,
                          props.admin,
                          setPhoto,
                        )}>
                        {icons.delete_icon}
                    </button>
                  )}
                  <img
                    className="image_style"
                    src={process.env.REACT_APP_API_GALLERY_IMAGES + photo}
                    width={imageWidth}
                    height={'auto'}
                    alt={'photo groupe 1 index ' + index} />
                </div>
              )}
            if (index % 3 === 0) {
              return (
                <div key={'galleryIt' + index} style={{position: 'relative'}}>
                  <button
                    className="fullScreenButton"
                    onClick={() => setGalleryFullScreen({disp: true, src: photo})}>
                      {icons.fullscreen_icon}
                  </button>
                  {props.admin.mode && (
                    <button
                      className="deleteGalleryImageButton"
                      onClick={() => deleteGalleryImage(
                          photo,
                          props.admin,
                          setPhoto,
                        )}>
                        {icons.delete_icon}
                    </button>
                  )}
                  <img
                    className="image_style"
                    src={process.env.REACT_APP_API_GALLERY_IMAGES + photo}
                    width={imageWidth}
                    height={'auto'}
                    alt={'photo groupe 1 index ' + index} />
                </div>
              )}
          })}
        </div>
      </div>
      {galleryFullScreen.disp && (
        <div className="row_flex_aro modalContainer" style={{zIndex: '5000'}}>
          <div className="row_flex_aro modalContent" style={{height: '96vh'}}>
            <button
              className="fullScreenButton"
              style={{top: '50px', right: '50px'}}
              onClick={() => setGalleryFullScreen({disp: false, src: ''})}>
                {icons.fullscreen_exit_icon}
            </button>
            <img
              className="image_fullscreen"
              src={process.env.REACT_APP_API_GALLERY_IMAGES + galleryFullScreen.src}
              width={imageWidth}
              height={'auto'}
              alt="Vue en plein écran" />
          </div>
        </div>
      )}      
      <hr className="hrStyle" style={{marginTop: '200px'}}></hr>
      <Footer />
      <ArrowUp />
    </div>
  )
}
