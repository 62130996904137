import axios from 'axios';
    
async function updateAnswer(
        page,
        index,
        admin,
        setAdmin,
        anw,
        setAnswer,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            services: 'updateAnswer',
            page: page,
            email: admin.email,
            api_identifier: admin.api_identifier,
            id: anw.id,
            title: anw.title,
            text: anw.text,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setAnswer(resp.data.Answers);
            document.querySelector('#updateAnswer' + index).style.background = 'green';
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })}
    )
}

export default updateAnswer;