import React, {useState} from 'react';

export default function HeaderText(props) {
    const [paraLength, setParaLength] = useState([]);

    return (
        <>
            {!props.admin.mode && (
                <div className="headerTextContainer noselect">
                    {props.headerText.map((txt, index) => (
                        <p
                            className="headerTextContent"
                            key={'headerText' + index}>
                                <span style={{marginLeft: '30px'}}></span>
                                {txt.text}
                        </p>
                    ))}
                </div>
            )}
            {props.admin.mode && (
                <div className="col_flex_aro headerTextAdminContainer">
                    <h4>Modifiez votre texte de haut de page</h4>
                    {props.headerText.map((txt, index) => (
                        <div key={"adminHeaderTxt" + index} className="col_flex_aro">
                            <div className="row_flex_aro">
                                <button
                                    type="button"
                                    className="butStyle_1"
                                    id={"delHeaderText" + index}
                                    onMouseOver={() => 
                                        document.querySelector('#delHeaderText' + index).style.transform =
                                            'scale(0.8)'}
                                    onMouseLeave={() => 
                                        document.querySelector('#delHeaderText' + index).style.transform =
                                            'scale(0.6)'}
                                    onClick={() => {
                                        props.deleteHeaderTextPara(
                                            props.admin,
                                            props.subject,
                                            index,
                                            props.headerText,
                                            props.setHeaderText,
                                        );
                                    }}
                                    style={{
                                        background: 'orange',
                                        transform: 'scale(0.6)',
                                        margin: '5px',
                                        position: 'relative',
                                        top: '10px'
                                    }}>Supprimer</button>
                                <button
                                    type="button"
                                    className="butStyle_1"
                                    id={"updateHeaderText"+ index}
                                    onClick={() => {
                                        props.updateHeaderTextPara(
                                            props.admin,
                                            props.subject,
                                            index,
                                            props.headerText,
                                            props.setHeaderText,
                                        );
                                        document.querySelector('#updateHeaderText' + index).style.background = 'green';
                                        }}          
                                    style={{
                                        background: 'green',
                                        transform: 'scale(0.6)',
                                        margin: '5px',
                                        position: 'relative',
                                        top: '10px'
                                    }}>UPDATE</button>
                            </div>
                            <span
                                style={{
                                    color: 
                                        paraLength < 1023
                                            ? 'blue'
                                            : 'red',
                                    fontSize: '14px',
                                    fontFamily: 'consolas',
                                    }}>
                                Paragraphe : {txt.text.length} / 1023 caractéres
                            </span>
                            <textarea
                                key={'headerText' + index}
                                className="headerTextFocus"
                                cols="100"
                                rows="6"
                                onChange={(e) => {
                                    if (e.target.value.length < 1024) {
                                        txt.text = e.target.value;
                                        let newHT = props.headerText;
                                        newHT[index] = txt
                                        props.setHeaderText(newHT);
                                        document.querySelector('#updateHeaderText' + index).style.background = 'lightgreen';
                                        setParaLength(txt.text.length)
                                    }
                                }}
                               value={txt.text}>
                            </textarea>
                        </div>
                    ))}
                    <div className="row_flex_aro">
                        <button
                            type="button"
                            className="butStyle_1"
                            id="addHeaderText"
                            onClick={() => 
                                props.addHeaderTextPara(
                                    props.admin,
                                    props.subject,
                                    props.setHeaderText,
                                )}          
                            style={{
                                background: 'magenta',
                                margin: '5px',
                                position: 'relative',
                                top: '20px'
                            }}>Ajouter</button>
                    </div>
                </div>
            )}
        </>
    )
}
