import axios from 'axios';
    
async function getCommentData(admin, setCommentData, setFond_F2, setFond_F3, online) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            comment: 'getData',
            api_identifier: admin.api_identifier,
            email: admin.email,
            onlineComment: online ? 1 : 0,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setCommentData(resp.data.CommentData);
            setFond_F2(resp.data.Image_F2);
            setFond_F3(resp.data.Image_F3);
        } else {
            setCommentData([]);
        }
    }).catch(() => setCommentData([]))
}

export default getCommentData; 