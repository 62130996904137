import React from 'react';
import updateAccueilPara from '../request/accueil/updateAccueilPara';
import deleteAccueilPara from '../request/accueil/deleteAccueilPara';

export default function UpdateOrDeletePara(props) {
    return (
        <div className="row_flex_aro" style={{width: '60vw'}}>
            <button
                type="button"
                className="butStyle_1"
                style={{
                    background:
                        (props.markUpdating.state && props.markUpdating.id === props.localAccueilState.id)
                            ? 'lightgreen'
                            : 'green'}}
                onClick={() => updateAccueilPara(
                    props.admin,
                    props.setAdmin,
                    props.setAccueilData,
                    props.localAccueilState,
                    props.setMarkUpdating,
                )}>
                Update
            </button>
            <button
                type="button"
                className="butStyle_1"
                style={{background: 'orange'}}
                onClick={() => deleteAccueilPara(
                    props.admin,
                    props.setAdmin,
                    props.setAccueilData,
                    props.localAccueilState,
                    props.setOldAccueilData
                    )}>
                Delete
            </button>
        </div>
    )
}
