import React from 'react';

export default function ManageArticleTitle(props) {
  return (
    <div className="col_flex_aro">
        <span
            style={{fontSize: '16px', fontWeight: '700', marginTop: '20px'}}>
            Titre
        </span>
        <span
            style={{
                color: props.localAccueilState.title.length < 28 ? 'blue' : 'red',
                fontSize: '14px',
                fontFamily: 'consolas',
                }}>
            Titre : {props.localAccueilState.title.length} / 28 caractéres
        </span>
        <input
            className="accueil_para_title setInputFocus"
            type="text"
            size="30"
            onChange={(e) => {
                if (e.target.value.length <= 28) {
                    props.setLocalAccueilState({
                        ...props.localAccueilState,
                        title: e.target.value,
                    });
                    props.setMarkUpdating({state: true, id: props.localAccueilState.id});
                }
            }}
            value={props.localAccueilState.title} />
    </div>
  )
}
