import React from 'react';
import changePassword from '../request/changePassword';

export default function ChangePassword(props) {

    return (
        <div className="modalContainer row_flex_aro dispModal">
            <div className="modalContent col_flex_aro">
                <div style={{marginTop: '100px'}}>
                    <h3>Changer de mot de passe</h3>
                </div>
                <div className="col_flex_aro" style={{height: '300px', marginBottom: '30px'}}>
                    <div>
                        <p>Your email : <span style={{fontSize: '18px'}}>{props.admin.email}</span></p>
                    </div>
                    {!props.changePwd.update && (
                        <>
                            <div className="col_flex_aro">
                                <label htmlFor="pwd1" style={{fontSize: '18px'}}>
                                    Actual password &nbsp;&nbsp;&nbsp;
                                    <span style={{fontSize: '14px'}}>{props.changePwd.actualPwd.length + ' / 24 chars'}</span>
                                </label>
                                <input
                                    type="text"
                                    id="pwd1"
                                    style={{fontSize: '18px', padding: '2px 5px'}}
                                    size="24"
                                    maxLength="24"
                                    onChange={(e) => props.setChangePwd({...props.changePwd, actualPwd: e.target.value})}
                                    value={props.changePwd.actualPwd} />
                            </div>
                            <div className="col_flex_aro" >
                                <label htmlFor="pwd2" style={{fontSize: '18px'}}>
                                    New password  &nbsp;&nbsp;&nbsp;
                                    <span style={{fontSize: '14px'}}>{props.changePwd.newPwd1.length + ' / 24 chars'}</span>
                                </label>
                                <input
                                    type="text"
                                    id="pwd2"
                                    style={{fontSize: '18px', padding: '2px 5px'}}
                                    size="24"
                                    maxLength="24"
                                    onChange={(e) => props.setChangePwd({...props.changePwd, newPwd1: e.target.value})}
                                    value={props.changePwd.newPwd1} />
                            </div>
                            <div className="col_flex_aro" >
                                <label htmlFor="pwd3" style={{fontSize: '18px'}}>
                                    Confirm &nbsp;&nbsp;&nbsp;
                                    <span style={{fontSize: '14px'}}>{props.changePwd.newPwd2.length + ' / 24 chars'}</span>
                                </label>
                                <input
                                    type="text"
                                    id="pwd3"
                                    style={{fontSize: '18px', padding: '2px 5px'}}
                                    size="24"
                                    maxLength="24"
                                    onChange={(e) => props.setChangePwd({...props.changePwd, newPwd2: e.target.value})}
                                    value={props.changePwd.newPwd2} />
                            </div>
                        </>
                    )}
                    {props.changePwd.update && (
                        <div>
                            <p style={{fontSize: '24px', color: 'lightgreen'}}>{props.changePwd.message}</p>
                        </div>
                    )}
                    {!props.changePwd.update && (
                        <div>
                            <p style={{fontSize: '24px', color: 'red'}}>{props.changePwd.message}</p>
                        </div>
                    )}
                </div>
                <div className="row_flex_aro" style={{width: '330px'}}>
                    {!props.changePwd.update && (
                        <button
                            className="butStyle_1"
                            onClick={() => {
                                if (props.changePwd.newPwd1 !== props.changePwd.newPwd2) {
                                    document.querySelector('#pwd2').style.color = 'red';
                                    document.querySelector('#pwd3').style.color = 'red';
                                } else {
                                    document.querySelector('#pwd2').style.color = 'black';
                                    document.querySelector('#pwd3').style.color = 'black';
                                    changePassword(
                                        props.admin,
                                        props.setAdmin,
                                        props.changePwd,
                                        props.setChangePwd,
                                    );
                                }
                                }}
                            style={{background: 'lightgreen'}}>UPDATE</button>
                    )}
                    <button
                        className="butStyle_1"
                        onClick={() =>
                            props.setChangePwd({
                                ...props.changePwd,
                                actualPwd: '',
                                newPwd1: '',
                                newPwd2: '',
                                dispChangePwdForm: false,
                            })}
                        style={{background: 'orange'}}>BACK</button>
                </div>
            </div>
        </div>
    )
}
