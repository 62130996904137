import axios from 'axios'

async function deleteAProposPara(
        admin,
        setAdmin,
        setAProposData,
        aProposData,
        setOldAProposData,
    ) {

await axios({
    method: 'post', 
    url: process.env.REACT_APP_SERVER_NAME,
    params: {},
    data: {
        apropos: 'rmPara',
        email: admin.email,
        api_identifier: admin.api_identifier,
        para_id: aProposData.id,
    },
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
    }
}).then ((resp) => {
    if (resp.status === 200) {
        setAProposData(resp.data.Data);
        setOldAProposData(resp.data.OldData);
    } else {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        });
    }
}).catch((e) => {
    setAdmin({
        ...admin,
        mode: false,
        pwd: "",
        api_identifier: '',
        seePwd: false,
        message: 'Erreur d\'identifiants ou de réseau',
    })}
)
}

export default deleteAProposPara;
