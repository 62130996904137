import axios from 'axios';
    
async function setNewComment(newComment, setCommentState, setNewComment) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            comment: 'setNewComment',
            comment_format: newComment.modelChoosen,
            comment_image: newComment.image,
            comment_color: newComment.color,
            comment_title: newComment.title,
            comment_comment: newComment.comment,
            comment_name: newComment.name,
            comment_email: newComment.email,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setCommentState({message: resp.data.message});
            setTimeout(() => {
                setCommentState({message: 'en cours'});
                setNewComment({
                    dispForm: false,
                    visu: false,
                    error: false,
                    title: '',
                    comment: '',
                    email: '',
                    name: '',
                    model: '',
                    chooseImage: false,
                    modelIndex: 0,
                    image: 'comment_fond_F2_1.png',
                    message: false,
                    color: '#110a4e',
                    seeModels: false, 
                    modelChoosen: 0,
                    commentEdit: true,
                });
            }, 2000);
        } else {
            setCommentState('error');
        }
    }).catch(() =>  setCommentState('error'))
}

export default setNewComment; 