import React, {useState} from 'react';
import TermesConditions from './TermesConditions';
import PolitiqueCookie from './PolitiqueCookie';
import MentionsLegales from './MentionsLegales';
import PolitiqueConfidentialite from './PolitiqueConfidentialite';
import '../style/footerStyle.css';

export default function Footer() {
    const [dispPolCook, setDispPolCook] = useState(false);
    const [dispPolConf, setDispPolConf] = useState(false);
    const [dispMentLeg, setDispMentLeg] = useState(false);
    const [dispTermCond, setDispTermCond] = useState(false);
  return (
    <div className="col_flex_aro footerContainer">
        <div className={
                window.screen.width > 800
                    ? "row_flex_aro"
                    : "col_flex_aro"
                }>
            {/* <div className="footerLink">
                <p onClick={() => setDispTermCond(true)}>Termes et conditions</p>
            </div> */}
            <div className="footerLink">
                <p onClick={() => setDispPolConf(true)}>Politique de confidentialité</p>
            </div>
            <div className="footerLink">
                <p onClick={() => setDispMentLeg(true)}>Mentions légales</p>
            </div>
            <div className="footerLink">
                <p onClick={() => setDispPolCook(true)}>Politique de cookies</p>
            </div>
        </div>
        <div className="col_flex_aro secondContent">
            <img
                className="sc_1"
                src={process.env.REACT_APP_API_IMAGES + '/footerLogo.png'}
                alt="Logo de Bélévenement" />
            <p className="sc_2">br.belevenement@hotmail.com</p>
            <p className="sc_2">
                <span style={{fontSize: '16px'}}>Tél : </span>
                <span style={{fontSize: '20px'}}>07 82 10 26 06</span>
            </p>
            <p
                className={window.screen.width > 400
                    ? "sc_2 row_flex_aro"
                    : "sc_2 col_flex_aro"}>
                <span style={{paddingTop: '10px'}}><i>@2024 par Bel Événement</i></span>
                <span style={{paddingTop: '10px'}}> - <i>créé par </i>
                    &nbsp;
                    <a
                        className="webMasterLink"
                        href="https://pascalcosta.fr"
                        target="_blank"
                        rel="noreferrer">
                            <i>Pascal Costa</i>
                    </a>
                </span>
            </p>
        </div>
        {dispTermCond && (
            <TermesConditions setDispTermCond={setDispTermCond}/>
        )}
        {dispPolConf && (
            <PolitiqueConfidentialite setDispPolConf={setDispPolConf}/>
        )}
        {dispMentLeg && (
            <MentionsLegales setDispMentLeg={setDispMentLeg}/>
        )}
        {dispPolCook && (
            <PolitiqueCookie setDispPolCook={setDispPolCook}/>
        )}
    </div>
  )
}
