import axios from 'axios';
    
async function deleteHeaderTextPara(
    admin,
    page,
    index,
    headerText,
    setHeaderText,
) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            services: 'deleteHeaderTextPara',
            api_identifier: admin.api_identifier,
            email: admin.email,
            page: page,
            index: headerText[index].id,
            text: headerText[index].text,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setHeaderText(resp.data.HeaderText);
        } else {
            setHeaderText([]);
        }
    }).catch(() => setHeaderText([]))
}

export default deleteHeaderTextPara; 