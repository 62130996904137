import axios from 'axios';
    
async function setCardContent(
        subject,
        admin,
        setAdmin,
        editCard,
        setEditCard,
        setNotice,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            services: subject,
            email: admin.email,
            api_identifier: admin.api_identifier,
            card_id: editCard.card.id,
            card_title: editCard.card.title,
            card_text_1: editCard.card.text_1,
            card_text_2: editCard.card.text_2,
            card_ps: editCard.card.postscriptum,
            card_online: editCard.card.online,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setNotice(resp.data.Notices);
            // if (subject === 'updateVoyagesCard') {
            //     setNotice(resp.data.voyagesNoticeData);
            // }
            // if (subject === 'updateMariagesCard') {
            //     setNotice(resp.data.mariagesNoticeData);
            // }
            // if (subject === 'updateLocationsCard') {
            //     setNotice(resp.data.locationsNoticeData);
            // }
            // if (subject === 'updateAutresCard') {
            //     setNotice(resp.data.autresNoticeData);
            // }
            setEditCard({...editCard, state: false})
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })}
    )
}

export default setCardContent;