import React, {useEffect} from 'react';
import getUsers from '../request/getUsers';
import updateOnlineUser from '../request/updateOnlineUser';

export default function ManageSettings(props) {

    useEffect(() => {
        getUsers(props.admin, props.setUsers)

        return;
    },[])
    return (
        <div className="modalContainer row_flex_aro dispModal">
            <div className="modalContent col_flex_aro">
                <div style={{marginTop: '100px'}}>
                    <h3>Liste des administrateurs</h3>
                </div>
                <div className="col_flex_aro">
                    <table>
                        <thead>
                            <tr>
                                <th style={{width: '220px'}}>Created_at</th>
                                <th style={{width: '250px'}}>Email</th>
                                <th style={{width: '80px'}}>Password</th>
                                <th style={{width: '50px'}}>online</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.users && props.users.map((user, index) => (
                                <tr key={'user' + index}>
                                    <td style={{width: '220px'}}>{user.created_at}</td>
                                    <td style={{width: '250px'}}>{user.email}</td>
                                    {props.admin.email === user.email ? (
                                    <td
                                        className="adminUserBut"
                                        onClick={() =>
                                            props.setChangePwd({
                                                ...props.changePwd,
                                                dispChangePwdForm: true,
                                            })}
                                        style={{width: '80px'}}>Change?</td>

                                    ) : (
                                        <td></td>
                                    )}
                                    <td
                                        className="adminUserBut"
                                        onClick={() => {
                                            updateOnlineUser(props.admin, user, props.setUsers);
                                        }}
                                        style={{width: '50px'}}>{user.is_verified}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <button
                            className="butStyle_1"
                            onClick={() => {
                                    props.setNewUser({...props.newUser, dispNewUserForm: true});
                                }}
                            style={{background: 'lightyellow', height: '50px'}}>
                                Ajouter un administrateur
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
