import React from 'react';

export default function TermesConditions(props) {
    return (
        <div className="col_flex_aro legalContainer" style={{width: '90vw'}}>
            <div className="row_flex_aro">
                <h2 className="legalTitle">Termes & conditions</h2>
            <div>
                <button
                    className="closeButDiv"
                    onClick={() => props.setDispTermCond(false)}
                    style={{
                        position: 'fixed',
                        top: '100px',
                        right: '5vw',
                        transform: 'scale(0.7)',
                    }}>X</button>
            </div>
            </div>
            <div className="legalPara">
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Ici les termes et conditions Ici les termes et conditions Ici les termes et conditions 
                    Ici les termes et conditions Ici les termes et conditions Ici les termes et conditions 
                    Ici les termes et conditions Ici les termes et conditions Ici les termes et conditions 
                    Ici les termes et conditions Ici les termes et conditions Ici les termes et conditions 
                    Ici les termes et conditions Ici les termes et conditions Ici les termes et conditions 
                    Ici les termes et conditions Ici les termes et conditions Ici les termes et conditions 
                    <br></br>
                </p>
            </div>
            <div style={{marginBottom: '200px'}}></div>
        </div>
      )
}
