import React from 'react';
import setAccueilNewImage from '../request/accueil/setAccueilNewImage';
import deleteAccueilImage from '../request/accueil/deleteAccueilImage';

export default function ManagementAccueilImage(props) {
    return (
        <div
            className="col_flex_aro"
            style={{
                border: '1px solid blue',
                marginTop: '10px',
                background: 'lightgrey',
                }}>
            {props.addImg && (
                <span style={{fontSize: '12px'}}><b>Format 200px / 200px</b></span>
            )}
            <div className="col_flex_aro">
                {props.localAccueilState.image !== '' && (
                    <div className="row_flex_aro">
                        <label className="accueilLabel">A gauche</label>
                        <input
                            type="radio"
                            name={props.localAccueilState.id + "image_place"}
                            id={props.localAccueilState.id + "image_place_left"}
                            value="left"
                            onChange={(e) => { 
                                props.setLocalAccueilState({
                                    ...props.localAccueilState,
                                    image_place: e.target.value
                                });
                                props.setMarkUpdating({state: true, id: props.localAccueilState.id});
                            }}
                            checked={props.localAccueilState.image_place === 'left'} />
                        <label className="accueilLabel" style={{margin: '0 20px'}}>De quel coté l'image?</label>
                        <input
                            type="radio"
                            name={props.localAccueilState.id + "image_place"}
                            id={props.localAccueilState.id + "image_place_right"}
                            value="right"
                            onChange={(e) => {
                                props.setLocalAccueilState({
                                    ...props.localAccueilState,
                                    image_place: e.target.value
                                });
                                props.setMarkUpdating({state: true, id: props.localAccueilState.id});
                            }}
                            checked={props.localAccueilState.image_place === 'right'} />
                        <label className="accueilLabel">A droite</label>
                    </div>
                )}
                <button
                    className="butStyle_1"
                    style={{background: 'lightblue', fontSize: '12px', width: '200px'}}
                    onClick={() => props.setAddImg(true)}>
                        {!props.addImg && 'Ajouer / changer une image'}
                        {props.addImg && (
                            <input
                                type="file"
                                className="fileInputBut"
                                onChange={(e) => {
                                    setAccueilNewImage(
                                        e.target.files[0],
                                        props.admin,
                                        props.setAdmin,
                                        props.setAccueilData,
                                        props.localAccueilState,
                                    );
                                }}
                            />
                        )}
                </button>
            </div>
            {props.localAccueilState.image !== '' && (
                <button
                    type="button"
                    className="butStyle_1"
                    style={{background: 'orange', fontSize: '12px', width: '200px'}}
                    onClick={() =>
                        deleteAccueilImage(
                            props.admin,
                            props.setAdmin,
                            props.setAccueilData,
                            props.localAccueilState,
                        )}>Supprimer l'image</button>
            )}
        </div>
    )
}
