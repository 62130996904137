import axios from 'axios';
    
async function setNewGalleryImage(
        file,
        admin,
        setPhoto,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            gallery: 'addGalleryImage',
            email: admin.email,
            api_identifier: admin.api_identifier,
            fileUpload: file,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setPhoto(resp.data.GalleryPhoto);
        } else {
            setPhoto([]);
        }
    }).catch((e) => setPhoto([]))
}

export default setNewGalleryImage;