import React, {useEffect} from 'react';

export default function ManageAProposTitle(props) {

    useEffect(() => {
        if (props.newData === null){
            props.setNewData(props.aProposData[props.index]);
        }

        return;
    }, [])
    
    return (
        <div className="col_flex_aro">
        {props.newData && props.newData.title && (
            <span
                style={{
                    color: (props.aProposData[props.index].title.length) < 28 ? 'blue' : 'red',
                    fontSize: '14px',
                    fontFamily: 'consolas',
                    }}>
                Titre : {props.aProposData[props.index].title.length} / 28 caractéres
            </span>
            )}
            <input
                className="accueil_para_title setInputFocus"
                type="text"
                size="18"
                onChange={(e) => {
                    if (e.target.value.length <= 28) {
                        props.changeParaTitle(e, props.index);
                    }
                }}
                value={props.aProposData[props.index].title} />
        </div>
    )
}
