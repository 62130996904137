import React, {useState, useEffect} from 'react';
import Footer from './Footer';
import ArrowUp from './ArrowUp';
import sendContactMail from './request/contact/sendContactMail';
import '../style/contactStyle.css';

export default function Contact(props) {
    const [emailContent, setEmailContent] = useState({
        'firstname': '',
        'lastname': '',
        'email': '',
        'eventType': '',
        'comment': '',
    });
    const [mailState, setMailState] = useState({
      state: false,
      fault: '',
      message: '',
    });
    const controlText = inputText => {
        var surText = inputText
            .replace(/&/g, "")
            .replace(/\$/g, "")
            .replace(/\|/g, "")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/;/g, "")
            .replace(/"/g, "")
            .replace(/{/g, "")
            .replace(/}/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "")
        return surText;
    };
    const onChangeInput = (
      eventType =
        emailContent.eventType !== null
          ? emailContent.eventType
          : null
      ) => {
        setEmailContent({
            'firstname': controlText(document.querySelector('#firstname').value),
            'lastname': controlText(document.querySelector('#lastname').value),
            'email': controlText(document.querySelector('#email').value),
            'eventType': eventType,
            'comment': controlText(document.querySelector('#comment').value),
        });
    };
    const sendContactElement = () => {
      if (emailContent.firstname === '') {
        setMailState({
          state: false,
          fault: 'firstname',
          message: 'Vous n\'avez pas écrit votre prénom',
        });
        setTimeout(() => setMailState({
          state: false,
          fault: 'firstname',
          message: '',
        }), 2000);
      } else if (emailContent.lastname === '') {
        setMailState({
          state: false,
          fault: 'lastname',
          message: 'Vous n\'avez pas écrit votre nom',

        });
        setTimeout(() => setMailState({
          state: false,
          fault: 'lastname',
          message: '',
        }), 2000);
      } else if (emailContent.email === '') {
        setMailState({
          state: false,
          fault: 'email',
          message: 'Vous n\'avez pas renseigné votre email',

        });
        setTimeout(() => setMailState({
          state: false,
          fault: 'email',
          message: ''
        }), 2000);
      } else if (emailContent.comment === '') {
        setMailState({
          state: false,
          fault: 'comment',
          message: 'Vous n\'avez pas écrit de commentaires',
        });
        setTimeout(() => setMailState({
          state: false,
          fault: 'comment',
          message: ''
        }), 2000);
      } else {
        sendContactMail(emailContent, setMailState);
        setMailState({
          state: true,
          fault: '',
          message: 'Envoie en cours',
        });
        setEmailContent({
          'firstname': '',
          'lastname': '',
          'email': '',
          'eventType': '',
          'comment': '',
      })
      }
    };

    useEffect(() => {
        window.scrollTo(0,0);

        // if (props.eventType) {
        //   setEmailContent({...emailContent, eventType: props.eventType});
        // }
        return;
    }, [])

  return (
    <div className={"col_flex_aro contactContainer " + props.class}>
      <div className="col_flex_aro">
        <h2 className="contactH2Title">FORMULAIRE DE CONTACT</h2>
      </div>
      <div id="contact" style={{paddingBottom: '80px'}}>
        <form className="col_flex_aro">
          {mailState.state && (
            <div className="confirmationMessage">
              <span>{mailState.message}</span>
            </div>
          )}
          {!mailState.state && mailState.message !== '' && (
            <div className={
                mailState === 'Envoie en cours'
                  ? "confirmationMessage"
                  :"faultMessage"
              }>
              <span>{mailState.message}</span>
            </div>
          )}
            {!mailState.state && (
              <div className="col_flex_aro">
                <div
                  className="row_flex_aro"
                  style={{
                    width: '90vw',
                    maxWidth: '750px',
                    flexFlow: 'wrap',
                    height: window.screen.width > 550 ? "80px" : "150px",
                    }}>
                  <div className="col_flex_aro inputText1">
                      <span className="inputLabel noselect">
                        Votre prénom
                      </span>
                      <input
                        type="text"
                        className="inputText"
                        name="firstname"
                        id="firstname"
                        size="20"
                        maxLength="40"
                        placeholder="Votre prénom"
                        style={{
                          background: mailState.fault === 'firstname'
                            ? '#efd0cf'
                            : 'white'
                        }}
                        onChange={() => onChangeInput()} />
                  </div>
                  <div className="col_flex_bet inputText1">
                      <span className="inputLabel noselect">
                        Votre nom
                      </span>
                      <input
                        type="text"
                        className="inputText"
                        name="lastname"
                        id="lastname"
                        size="20"
                        maxLength="40"
                        placeholder="Votre nom"
                        style={{
                          background: mailState.fault === 'lastname'
                            ? '#efd0cf'
                            : 'white'
                        }}
                        onChange={() => onChangeInput()} />
                  </div>
                </div>
                <div className="col_flex_aro inputText2">
                    <span className="inputLabel noselect">
                      Votre email
                    </span>
                    <input
                      type="email"
                      className="inputText"
                      name="email"
                      id="email"
                      size={window.screen.width > 550 ? "48" : "30"}
                      maxLength="255"
                      placeholder="Votre email"
                        style={{
                          background: mailState.fault === 'email'
                            ? '#efd0cf'
                            : 'white'
                        }}
                      onChange={() => onChangeInput()} />
                </div>
                <div className="inputBox">
                    <div
                      className={
                        window.screen.width > 500
                          ? "row_flex_aro radioButContainer"
                          : "col_flex_aro radioButContainer"
                      }>
                      <div
                        className="row_flex_bet"
                        style={{
                          width: '100px',
                          minHeight: window.screen.width < 500 ? '40px' : '0'
                        }}>
                        <label
                          className="inputLabel noselect"
                          style={{
                            textDecoration:
                              emailContent.eventType === 'mariage'
                                ? 'underline'
                                : 'none',
                            }}
                            htmlFor="mariage">Mariage</label>
                        <input
                          className="inputRadio"
                          type="radio"
                          name="eventType"
                          id="mariage"
                          onChange={(e) => onChangeInput(e.target.value)}
                          value="mariage"
                          checked={emailContent.eventType === 'mariage'} />
                      </div>
                      <div
                        className="row_flex_bet"
                        style={{
                          width: '100px',
                          minHeight: window.screen.width < 500 ? '40px' : '0'
                        }}>
                        <label
                          className="inputLabel noselect"
                          style={{
                            textDecoration:
                              emailContent.eventType === 'voyage'
                                ? 'underline'
                                : 'none',
                            }}
                            htmlFor="voyage">Voyage</label>
                        <input
                          className="inputRadio"
                          type="radio"
                          name="eventType"
                          id="voyage"
                          onChange={(e) => onChangeInput(e.target.value)}
                          value="voyage"
                          checked={emailContent.eventType === 'voyage'} />
                      </div>
                      <div
                        className="row_flex_bet"
                        style={{
                          width: '100px',
                          minHeight: window.screen.width < 500 ? '40px' : '0'
                        }}>
                        <label
                          className="inputLabel noselect"
                          style={{
                            textDecoration:
                              emailContent.eventType === 'location'
                                ? 'underline'
                                : 'none',
                            }}
                            htmlFor="location">Location</label>
                        <input
                          className="inputRadio"
                          type="radio"
                          name="eventType"
                          id="location"
                          onChange={(e) => onChangeInput(e.target.value)}
                          value="location"
                          checked={emailContent.eventType === 'location'} />
                      </div>
                      <div
                        className="row_flex_bet"
                        style={{
                          width: '100px',
                          minHeight: window.screen.width < 500 ? '40px' : '0'
                        }}>
                        <label
                          className="inputLabel noselect"
                          style={{
                            textDecoration:
                              emailContent.eventType === 'autre'
                                ? 'underline'
                                : 'none',
                            }}
                            htmlFor="autre">Autre</label>
                        <input
                          className="inputRadio"
                          type="radio"
                          name="eventType"
                          id="autre"
                          onChange={(e) => onChangeInput(e.target.value)}
                          value="autre"
                          checked={emailContent.eventType === 'autre'} />
                      </div>
                    </div>
                </div>
                <br></br>
                <div
                  className="col_flex_aro"
                  style={{paddingTop: window.screen.width > 550 ? '0' : '110px'}}>
                    <label
                      className="inputLabel noselect"
                      htmlFor="autre">Rédigez un message</label>
                    <textarea
                      className="areaBox"
                      id="comment"
                      name="comment" 
                      rows="4"
                      cols={window.screen.width > 550 ? "54" : "34"}
                      maxLength="512"
                      placeholder="Votre message?"
                        style={{
                          background: mailState.fault === 'comment'
                            ? '#efd0cf'
                            : 'white'
                        }}
                      onChange={() => onChangeInput()}></textarea>
                </div>
                <div
                  className={window.screen.width > 500 ? "row_flex_aro" : "col_flex_aro"}
                  style={{paddingTop: '25px'}}>
                  <button
                    type="button"
                    className="butStyle_1"
                    style={{
                      background: 'black',
                      color: 'white',
                      borderRadius: '32px',
                      width: '200px',
                      height: '50px',
                      fontSize: '20px',
                      transform: window.screen.width > 550 ? 'scale(1)' : 'scale(0.6)'}}
                    onClick={() => sendContactElement()}>
                      Envoyer</button>
                    <p className="inputLabel noselect" style={{margin: '0 15px'}}>
                      Merci pour votre envoi !
                    </p>
                </div>
              </div>
            )}
        </form>
      </div>
      <hr className="hrStyle"></hr>
      <Footer />
      <ArrowUp />
    </div>
  )
}
