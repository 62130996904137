import React from 'react';
import setNewUser from '../request/setNewUser';

export default function SetNewUser(props) {
    return (
        <div className="modalContainer row_flex_aro dispModal">
            <div className="modalContent col_flex_aro">
                <div style={{marginTop: '100px'}}>
                    <h3>Changer de mot de passe</h3>
                </div>
                <div className="col_flex_aro" style={{height: '300px', marginBottom: '30px'}}>
                    <div>
                        <p>Your email : <span style={{fontSize: '18px'}}>{props.admin.email}</span></p>
                    </div>
                    {/* {!props.changePwd.update && ( */}
                        <>
                            <div className="col_flex_aro">
                                <label htmlFor="email" style={{fontSize: '18px'}}>
                                    Email &nbsp;&nbsp;&nbsp;
                                    <span style={{fontSize: '14px'}}>{props.newUser.email.length + ' / 256 chars'}</span>
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    style={{fontSize: '18px', padding: '2px 5px'}}
                                    size="24"
                                    maxLength="256"
                                    onChange={(e) => props.setNewUser({...props.newUser, email: e.target.value})}
                                    value={props.newUser.email}
                                    required />
                            </div>
                            <div className="col_flex_aro" >
                                <label htmlFor="password" style={{fontSize: '18px'}}>
                                    Password  &nbsp;&nbsp;&nbsp;
                                    <span style={{fontSize: '14px'}}>{props.newUser.pwd.length + ' / 24 chars'}</span>
                                </label>
                                <input
                                    type="text"
                                    id="password"
                                    style={{fontSize: '18px', padding: '2px 5px'}}
                                    size="24"
                                    maxLength="24"
                                    onChange={(e) => props.setNewUser({...props.newUser, pwd: e.target.value})}
                                    value={props.newUser.pwd}
                                    required />
                            </div>
                            <div className="row_flex_aro" >
                                <label htmlFor="isVerified" style={{fontSize: '18px'}}>
                                    Authorization
                                </label>
                                <input
                                    type="checkbox"
                                    id="isVerified"
                                    style={{fontSize: '18px', margin: '0 30px', transform: 'scale(2)'}}
                                    size="24"
                                    maxLength="24"
                                    onChange={(e) => props.setNewUser({...props.newUser, is_verified: e.target.checked})}
                                    checked={props.newUser.is_verified} />
                            </div>
                        </>
                    {/* )} */}
                    {/* {props.changePwd.update && ( */}
                        {/* <div>
                            <p style={{fontSize: '24px', color: 'lightgreen'}}>{props.changePwd.message}</p>
                        </div> */}
                    {/* )} */}
                    {/* {!props.changePwd.update && ( */}
                        {/* <div>
                            <p style={{fontSize: '24px', color: 'red'}}>{props.changePwd.message}</p>
                        </div> */}
                    {/* )} */}
                </div>
                <div className="row_flex_aro" style={{width: '330px'}}>
                    <button
                        className="butStyle_1"
                        onClick={() => {
                                setNewUser(
                                    props.admin,
                                    props.setAdmin,
                                    props.newUser,
                                    props.setNewUser,
                                    props.setUsers
                                )
                            }}
                        style={{background: 'lightgreen'}}>CREATE</button>
                    <button
                        className="butStyle_1"
                        onClick={() =>
                            props.setNewUser({
                                ...props.newUser,
                                email: '',
                                pwd: '',
                                is_verified: '',
                                dispNewUserForm: false,
                            })}
                        style={{background: 'orange'}}>BACK</button>
                </div>
            </div>
        </div>
    )
}
