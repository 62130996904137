import React from 'react';

export default function DisplayAdminMenu(props) {
    return (
        <div className="row_flex_aro adminExitLink noselect">
            <span>
                {!props.adminMenu.deploy && (
                    <span
                        className="adminMenu"
                        onClick={() =>
                            props.setAdminmenu({...props.adminMenu, deploy: true})}>
                            {props.icons.settings_icon}
                    </span>
                )}
                {props.adminMenu.deploy && (
                    <div className="col_flex_aro adminMenu">
                        <span
                            className="adminBut"
                            onClick={() =>
                                props.setAdminmenu({deploy: false, choice: ''})}>
                            Retract menu</span>
                        <span
                            className="adminBut"
                            style={{
                                background: props.adminMenu.choice === 'logs'
                                    ? 'lightyellow'
                                    : 'lightgrey',
                                }}
                            onClick={() =>
                                props.setAdminmenu({...props.adminMenu, choice: 'logs'})}>
                            See logs</span>
                        <span
                            className="adminBut"
                            style={{
                                background: props.adminMenu.choice === 'settings'
                                    ? 'lightyellow'
                                    : 'lightgrey',
                                }}
                            onClick={() =>
                                props.setAdminmenu({...props.adminMenu, choice: 'settings'})}>
                            Settings</span>
                    </div>
                )}
                <span onClick={() => {
                        props.setAdmin({
                            mode: false,
                            dispPwdInput: false,
                            email: '',
                            pwd: '',
                            seePwd: false,
                            api_identifier: '',
                        });
                        props.setAdminmenu({deploy: false, choice: ''})
                    }}>Quitter le mode admin</span>
                </span>
        </div>
    )
}
