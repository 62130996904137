import axios from 'axios';
    
async function updateAProposPara(
        admin,
        setAdmin,
        setAProposData,
        setOldAProposData,
        aProposData,
        setMarkUpdating,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            apropos: 'updatePara',
            email: admin.email,
            api_identifier: admin.api_identifier,
            para_id: aProposData.id,
            para_title: aProposData.title,
            para_text_1: aProposData.text_1,
            para_text_2: aProposData.text_2,
            para_postscriptum: aProposData.postscriptum,
            para_image_place: aProposData.image_place,
            para_image_bg_color: aProposData.image_bg_color,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) { 
            setAProposData(resp.data.Data);
            setOldAProposData(resp.data.OldData);
            setMarkUpdating({state: false, id: null});
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
            setMarkUpdating({state: false, id: null});
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        });
        setMarkUpdating({state: false, id: null});
        }
    )
}

export default updateAProposPara;