import React from 'react';

export default function ParaText2Element(props) {
    return (
        <div className="col_flex_aro accueil_para_text">
            {props.localAccueilState.text_2 && !props.admin.mode && (
                    <p><span style={{paddingLeft: '30px'}}></span>{props.localAccueilState.text_2}</p>
            )}
            {props.localAccueilState.text_2 && props.admin.mode && (
                <>
                    <span
                        style={{fontSize: '16px', fontWeight: '700', marginTop: '20px'}}>
                        Paragraphe 2
                    </span>
                    <span
                        style={{
                            color: props.localAccueilState.text_2.length < 1023 ? 'blue' : 'red',
                            fontSize: '14px',
                            fontFamily: 'consolas',
                            }}>
                        Titre : {props.localAccueilState.text_2.length} / 1023 caractéres
                    </span>
                    <textarea
                        className="accueil_para_text setInputFocus"
                        cols={window.screen.width > 840 ? 76 : 30}
                        rows="5"
                        onChange={(e) => {
                            if (e.target.value.length <= 1023) {
                                props.setLocalAccueilState({
                                        ...props.localAccueilState,
                                        text_2: e.target.value,
                                });
                                props.setMarkUpdating({state: true, id: props.localAccueilState.id});
                            }
                        }}
                        value={props.localAccueilState.text_2}></textarea>
                </>
            )}
        </div>
    )
}
