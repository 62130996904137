import React from 'react';

export default function PolitiqueCookie(props) {
    return (
        <div className="col_flex_aro legalContainer" style={{width: '90vw'}}>
            <div className="row_flex_aro">
                <h2 className="legalTitle">Politique des cookies</h2>
                <div>
                    <button
                    className="closeButDiv"
                    onClick={() => props.setDispPolCook(false)}
                    style={{
                        position: 'fixed',
                        top: '100px',
                        right: '5vw',
                        transform: 'scale(0.7)',
                    }}>X</button>
                </div>
            </div>
            <div className="legalPara">
                <h3>1. Introduction</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Cette politique de cookies explique comment Bel Événement utilise des 
                    cookies et des technologies similaires sur son site web www.belevenement.fr . 
                    En utilisant notre site, vous acceptez l'utilisation de cookies conformément 
                    à cette politique.
                </p>
                <h3>2. Qu'est-ce qu'un cookie ?</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Un cookie est un petit fichier texte qui est placé sur votre appareil 
                    (ordinateur, tablette, smartphone) lorsque vous visitez un site web. 
                    Les cookies permettent au site de reconnaître votre appareil et de collecter 
                    des informations sur votre navigation.
                </p>
                <h3>3. Types de cookies utilisés</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Nous utilisons différents types de cookies :
                </p>
                <div style={{marginLeft: '40px'}}>
                    <h4>Cookies essentiels</h4>
                    <p>
                        <span style={{marginLeft: '30px'}}></span>
                        Ces cookies sont nécessaires au fonctionnement du site et vous permettent 
                        d'accéder à des fonctionnalités de base.
                    </p>
                    <h4>Cookies de performance</h4>
                    <p>
                        <span style={{marginLeft: '30px'}}></span>
                        Ces cookies collectent des informations sur la manière dont les visiteurs 
                        utilisent notre site, par exemple, les pages les plus visitées. Ces données 
                        nous aident à améliorer notre site.
                    </p>
                    <h4>Cookies de fonctionnalité</h4>
                    <p>
                        <span style={{marginLeft: '30px'}}></span>
                        Ces cookies permettent de mémoriser vos choix (comme votre nom d'utilisateur 
                        ou votre langue) et d'améliorer votre expérience de navigation.
                    </p>
                    <h4>Cookies de ciblage/publicité</h4>
                    <p>
                        <span style={{marginLeft: '30px'}}></span>
                        Ces cookies sont utilisés pour vous proposer des publicités plus pertinentes 
                        et adaptées à vos intérêts. Ils peuvent également limiter le nombre de fois 
                        que vous voyez une annonce et aident à mesurer l'efficacité des campagnes 
                        publicitaires.
                    </p>
                </div>
                <h3>4. Gestion des cookies</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Vous pouvez gérer vos préférences en matière de cookies directement dans votre navigateur. 
                    La plupart des navigateurs vous permettent de :
                </p>
                <ul>
                    <li>Voir les cookies qui sont stockés sur votre appareil.</li>
                    <li>Supprimer des cookies.</li>
                    <li>Bloquer les cookies de certains sites ou tous les cookies.</li>
                </ul>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Pour plus d'informations sur la gestion des cookies, vous pouvez consulter les 
                    sections d'aide de votre navigateur.
                </p>
                <h3>5. Consentement</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Lors de votre première visite sur notre site, un bandeau d'information s'affiche 
                    pour vous informer de l'utilisation des cookies. En poursuivant votre navigation 
                    sur notre site, vous consentez à l'utilisation de cookies conformément à cette 
                    politique.
                </p>
                <h3>6. Modifications de la politique de cookies</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Nous nous réservons le droit de modifier cette politique de cookies à tout moment. 
                    Les changements seront publiés sur notre site web avec une date de mise à jour.
                </p>
                <h3>7. Contact</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Pour toute question concernant cette politique de cookies, veuillez nous contacter à :
                </p>
                <br></br>
                <p style={{margin: '5px'}}>
                Bel Événement
                </p>
                <p style={{margin: '5px'}}>
                883 route d’Olmet - 34700 Lodève
                </p>
                <p style={{margin: '5px'}}>
                07 82 10 26 06
                </p>
                <p style={{margin: '5px', color: 'blue'}}>
                br.belevenement@hotmail.com
                </p>



            </div>
            <div style={{marginBottom: '200px'}}></div>
        </div>
    )
}
