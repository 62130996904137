import React, {useState} from 'react';
import deleteBankImage from '../request/comment/deleteBankImage';
import setNewBankImage from '../request/comment/setNewBankImage';

export default function CommentImageBankManagement(props) {
    const [errorMessage, setErrorMesssage] = useState ({dssp: false, message: ''});

  return (
    <div className="modalContainer row_flex_aro" style={{zIndex: '5000'}}>
        <div className="modalContent">
            <button className="closeButDiv" onClick={() => props.setImgBank(false)}>X</button>
            <div className="row_flex_aro">
                <h4 style={{color: 'lightblue', height: '30px'}}>Gérez votre banque d'image</h4>
            </div>
            <hr></hr>
            <div className="col_flex_aro">
                <div className="row_flex_aro">
                    <div className="col_flex_aro">
                        <h5
                            style={{
                                margin: '8px',
                                padding: '0',
                                fontSize: '18px',
                                color: 'lightblue',
                            }}>
                            Image pour les commentaires de format "images pleines"
                        </h5>
                        <span style={{
                            margin: '0',
                            padding: '0',
                            fontSize: '12px',
                            color: 'lightyellow',
                        }}>
                            <u>Format conseiilé : </u>800px / 450px, png ou jpg, 8 Mo max
                        </span>
                    </div>
                    <input
                        type="file"
                        onChange={(e) => {
                            setNewBankImage(
                                e.target.files[0],
                                'F2',
                                props.admin,
                                props.setAdmin,
                                props.setFond_F2,
                                props.setFond_F3,)
                            }}
                        style={{margin: '0 10px', color: 'lightblue'}}/>
                </div>
                <ul
                    className="row_flex_aro"
                    id="imageF2Container"
                    style={{width: '100%', overflow: 'auto', listStyle: 'none'}}>
                    {props.fond_F2.map((img, index) => (
                        <li
                            key={'format1img' + index}
                            style={{position: 'relative', margin: '0 10px'}}
                            id={img.replace(' ', '_')}>
                            <button
                                className="deleteBankImg"
                                onClick={() => deleteBankImage(
                                    props.admin,
                                    'F2',
                                    img,
                                    props.setFond_F2,
                                    props.setFond_F3,
                                )}>X</button>
                            <img
                                width="120"
                                src={process.env.REACT_APP_API_COMMENT_IMAGES + img}
                                alt={'Banque format 1, modele ' + index} />
                        </li>
                    ))}
                </ul>
            </div>
            <hr></hr>
            <div className="col_flex_aro">
                <div className="row_flex_aro">
                    <div className="col_flex_aro">
                        <h5
                            style={{
                                margin: '8px',
                                padding: '0',
                                fontSize: '18px',
                                color: 'lightblue',
                            }}>Image pour les commentaires de format "images à coté"</h5>
                            <span style={{
                                margin: '0',
                                padding: '0',
                                fontSize: '12px',
                                color: 'lightyellow',
                            }}>
                            <u>Format conseiilé : </u>240px / 240px, png ou jpg, 8 Mo max
                            </span>
                    </div>
                    <input
                        type="file"
                        onChange={(e) => {
                            setNewBankImage(
                                e.target.files[0],
                                'F3',
                                props.admin,
                                props.setAdmin,
                                props.setFond_F2,
                                props.setFond_F3,)
                            }}
                        style={{margin: '0 10px', color: 'lightblue'}}/>
                </div>
                <ul
                    className="row_flex_aro"
                    id="imageF3Container"
                    style={{width: '100%', overflow: 'auto', listStyle: 'none'}}>
                    {props.fond_F3.map((img, index) => (
                        <li
                            key={'format1img' + index}
                            style={{position: 'relative', margin: '0 10px'}}
                            id={img.replace(' ', '_')}>
                            <button
                                className="deleteBankImg"
                                onClick={() => deleteBankImage(
                                    props.admin,
                                    'F3',
                                    img,
                                    props.setFond_F2,
                                    props.setFond_F3,
                                )}>X</button>
                            <img
                                width="120"
                                src={process.env.REACT_APP_API_COMMENT_IMAGES + img}
                                alt={'Banque format 1, modele ' + index} />
                        </li>
                    ))}
                </ul>
            </div>
            <hr></hr>
        </div>
    </div>
  )
}
