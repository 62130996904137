import axios from 'axios';
    
async function changePassword(admin, setAdmin, changePwd, setChangePwd) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            user: 'changePwd',
            api_identifier: admin.api_identifier,
            email: admin.email,
            password: changePwd.actualPwd,
            newPassword: changePwd.newPwd1,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setChangePwd({
                dispChangePwdForm: true,
                actualPwd: '',
                newPwd1: '',
                newPwd2: '',
                update: resp.data.update,
                message: resp.data.message
            });
            if (resp.data.update) {
                setTimeout(() => 
                    setChangePwd({
                        dispChangePwdForm: false,
                        actualPwd: '',
                        newPwd1: '',
                        newPwd2: '',
                    }),
                    2000,
                );
            }
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
            setChangePwd({
                dispChangePwdForm: false,
                actualPwd: '',
                newPwd1: '',
                newPwd2: '',
            })
        }
    }).catch(() => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        });
        setChangePwd({
            dispChangePwdForm: false,
            actualPwd: '',
            newPwd1: '',
            newPwd2: '',
        })
    })
}

export default changePassword;