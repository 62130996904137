import axios from 'axios';
    
async function setOnlineComment(
        admin,
        id,
        online,
        commentData,
        setCommentData,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            comment: 'setOnline',
            email: admin.email,
            api_identifier: admin.api_identifier,
            comment_id: id,
            online: online,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) { 
            if (resp.data.message === 'La mise en ligne est modifiée') {
                let newData = commentData;
                newData.map((dt, index) => {
                    if (dt.id === id) {
                        newData[index].online = online;
                    }
                })
                setCommentData(newData);
                document.querySelector('#commentId' + id).style.background =
                    !online ? '#b18080' : 'lightgreen';
                document.querySelector('#commentId' + id).innerText =
                    !online ? 'Hors ligne' : 'En ligne';
            }
        }
    }).catch(() =>  console.log('error'))
}

export default setOnlineComment; 