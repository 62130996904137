import React, {useState, useEffect, useRef} from 'react';
import Header from './component/Header';
import Accueil from './component/Accueil';
import APropos from './component/APropos';
import CookiesMessage from './component/CookiesMessage';
import ManageSettings from './component/adminComponents/ManageSettings';
import DisplayAdminMenu from './component/adminComponents/DisplayAdminMenu';
import ConnectionForm from './component/adminComponents/ConnectionForm';
import ChangePassword from './component/adminComponents/ChangePassword';
import SetNewUser from './component/adminComponents/SetNewUser';
import DisplayLogs from './component/adminComponents/DisplayLogs';
import ServicesVoyages from './component/ServicesVoyages';
import ServicesMariages from './component/ServicesMariages';
import ServicesLocations from './component/ServicesLocations';
import ServicesAutres from './component/ServicesAutres';
import Contact from './component/Contact';
import PlusGalerie from './component/PlusGalerie';
import PlusTemoignages from './component/PlusTemoignages';
import {HashRouter, Routes, Route} from "react-router-dom";
import resumNavBar from './component/headerFcts/resumNavBar';
import expandNavBar from './component/headerFcts/expandNavBar';
import resumMenuWithScreenWidth from './component/headerFcts/resumMenuWithScreenWidth';
import reducSecondaryMenu from './component/headerFcts/reducSecondaryMenu';
import setIdentificationAdmin from './component/request/setIdentificationAdmin';
import './App.css';
import icons from './icons.js';
import './style/headerStyle.css';
import './style/inputButStyle.css';

function App() {
  const [demoInfoDisplay, setDemoInfoDisplay] = useState(true);
  const [cookiesDisplay, setCookiesDisplay] = useState(true);
  const [admin, setAdmin] = useState({
    mode: false,
    dispPwdInput: false,
    email: '',
    pwd: '',
    seePwd: false,
    api_identifier: '',
  });
  const [users, setUsers] = useState([]);
  const [adminMenu, setAdminmenu] = useState({
    deploy: false,
    choice: '',
  });
  const [reload, setReload] = useState(false);
  const [menu, setMenu] = useState({
    menu1: "Accueil",
    menu2: '',
    menu3: '',
  });
  const old_scroll_value = useRef(0);
  const [screenWidth, setScreenWidth] = useState(null);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [changePwd, setChangePwd] = useState({
    dispChangePwdForm: false,
    actualPwd: '',
    newPwd1: '',
    newPwd2: '',
  });
  const [newUser, setNewUser] = useState({
    dispNewUserForm: false,
    email: '',
    pwd: '',
    is_verified: '',
  });

  useEffect(() => {
    if (!reload && window.scrollY > 10) {
      resumNavBar();
      setTimeout(() => setReload(true), 600);
    }
    setScreenWidth(window.screen.width);
    window.addEventListener('scroll', () => {
      if (screenWidth > 600) {
        if (window.scrollY < old_scroll_value.current && window.scrollY <= 10) {
          expandNavBar();
        }
        if (window.scrollY > old_scroll_value.current && window.scrollY > 10) {
          resumNavBar();
        }
        old_scroll_value.current = window.scrollY;
      }
    });
    document.querySelector('.menuResum').addEventListener('mouseover', () => {
      setDisplayMenu(true);
    })
    document.querySelector('.menuResum').addEventListener('mouseout', () => {
      setDisplayMenu(false);
      reducSecondaryMenu();
    })
    resumMenuWithScreenWidth(screenWidth);

    return ;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth])
  return (
    <div className="App" id="topApp">
      <HashRouter>
        <Header
          menu={menu}
          setMenu={setMenu}
          screenWidth={screenWidth}
          displayMenu={displayMenu}
          setDisplayMenu={setDisplayMenu} />
        {/* <div className="row_flex_aro h1_div">
          <h1>Organisation d'événements sur-mesure</h1>
        </div> */}
        <Routes>
          <Route
            path='/'
            element={
            <Accueil
              admin={admin}
              setAdmin={setAdmin} 
              menu={menu}
              setMenu={setMenu} />} />
          <Route
            path='/A-propos'
            element={<APropos admin={admin} setAdmin={setAdmin} />} />
          <Route
            path='/Services'
            element={
            <ServicesVoyages
              admin={admin}
              setAdmin={setAdmin} 
              menu={menu}
              setMenu={setMenu} />} />
          <Route
            path='/Services/Voyages'
            element={
            <ServicesVoyages
              admin={admin}
              setAdmin={setAdmin} 
              menu={menu}
              setMenu={setMenu} />} />
          <Route
            path='/Services/Mariages'
            element={
            <ServicesMariages
              admin={admin}
              setAdmin={setAdmin} 
              menu={menu}
              setMenu={setMenu} />} />
          <Route
            path='/Services/Locations'
            element={
            <ServicesLocations
              admin={admin}
              setAdmin={setAdmin} 
              menu={menu}
              setMenu={setMenu} />} />
          <Route
            path='/Services/Autre-evenement'
            element={
            <ServicesAutres
              admin={admin}
              setAdmin={setAdmin} 
              menu={menu}
              setMenu={setMenu} />} />
          <Route
            path='/Contact'
            element={<Contact admin={admin} setAdmin={setAdmin} />} />
          <Route
            path='/Plus'
            element={<PlusGalerie admin={admin} setAdmin={setAdmin} />} />
          <Route
            path='/Plus/Galerie'
            element={<PlusGalerie admin={admin} setAdmin={setAdmin} />} />
          <Route
            path='/Plus/Temoignages'
            element={<PlusTemoignages admin={admin} setAdmin={setAdmin} />} />
        </Routes>
          {/* {!displayMenu && (
            <div
              className="row_flex_aro"
              style={{
                width: '30vw', height: '90vh',
                position: 'fixed', top: '20vh', left: '35vw',
                }}>
              <img
                src="https://clipartmag.com/images/website-under-construction-image-6.gif"
                alt="Website under construction"/>
            </div>
          )} */}
      </HashRouter>
      {admin.dispPwdInput && admin.api_identifier === '' && (
        <ConnectionForm
          admin={admin}
          setAdmin={setAdmin}
          icons={icons}
          setIdentificationAdmin={setIdentificationAdmin} />
      )}
      {(adminMenu.choice === 'settings'
        && admin.api_identifier !== ''
        && !changePwd.dispChangePwdForm) && (
        <ManageSettings
          admin={admin}
          setAdmin={setAdmin}
          icons={icons}
          adminMenu={adminMenu}
          setAdminmenu={setAdminmenu}
          changePwd={changePwd}
          setChangePwd={setChangePwd}
          newUser={newUser}
          setNewUser={setNewUser}
          users={users}
          setUsers={setUsers} />
      )}
      {(adminMenu.choice === 'settings'
        && admin.api_identifier !== '')
        && changePwd.dispChangePwdForm && (
        <ChangePassword
          admin={admin}
          setAdmin={setAdmin}
          icons={icons}
          adminMenu={adminMenu}
          setAdminmenu={setAdminmenu}
          changePwd={changePwd}
          setChangePwd={setChangePwd} />
      )}
      {(adminMenu.choice === 'settings'
        && admin.api_identifier !== '')
        && newUser.dispNewUserForm && (
        <SetNewUser
          admin={admin}
          setAdmin={setAdmin}
          icons={icons}
          adminMenu={adminMenu}
          setAdminmenu={setAdminmenu}
          newUser={newUser}
          setNewUser={setNewUser}
          setUsers={setUsers} />
      )}
      {(adminMenu.choice === 'logs'
        && admin.api_identifier !== '') && (
        <DisplayLogs
          admin={admin}
          setAdmin={setAdmin}
          icons={icons} />
      )}
      {admin.mode && (
        <DisplayAdminMenu 
          admin={admin}
          setAdmin={setAdmin}
          icons={icons}
          adminMenu={adminMenu}
          setAdminmenu={setAdminmenu} />
      )}
      {cookiesDisplay && (
        <CookiesMessage
          setCookiesDisplay={setCookiesDisplay} />
      )}
    </div>
  );
}

export default App;
