import axios from 'axios';
    
async function setNewImageBank(
        file,
        bank,
        admin,
        setAdmin,
        setFond_F2,
        setFond_F3,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            comment: 'setNewBankImage',
            email: admin.email,
            api_identifier: admin.api_identifier,
            fileUpload: file,
            bank: bank,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setFond_F2(resp.data.Image_F2);
            setFond_F3(resp.data.Image_F3);
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {console.log('error')}
    )
}

export default setNewImageBank;