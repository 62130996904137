import React, {useState, useEffect} from 'react';
import UpdateOrDeletePara from './UpdateOrDeletePara';
import ParaText1Element from './ParaText1Element';
import ParaText2Element from './ParaText2Element';
import ManagementAccueilImage from './ManagementAccueilImage';
import ManageArticleTitle from './ManageArticleTitle';

export default function ParaWithImageOnLeft(props) {
    const [addImg, setAddImg] = useState(false);
    const [localAccueilState, setLocalAccueilState] = useState([]);
    useEffect(() => {
        setLocalAccueilState(props.dt);

        return;
    }, [props.dt])
    return (
        <div className="accueil_para noselect">
            {localAccueilState.title &&  !props.admin.mode && (
                <div className="col_flex_aro accueil_para_title">
                    <h3><i>{localAccueilState.title}</i></h3>
                </div>
            )}
            {localAccueilState.title &&  props.admin.mode && (
                <ManageArticleTitle
                    setMarkUpdating={props.setMarkUpdating}
                    localAccueilState={localAccueilState}
                    setLocalAccueilState={setLocalAccueilState} />
            )}
            {props.admin.mode && (
                <ManagementAccueilImage 
                    addImg={addImg}
                    setAddImg={setAddImg} 
                    admin={props.admin}
                    setAdmin={props.setAdmin}
                    setMarkUpdating={props.setMarkUpdating}
                    setAccueilData={props.setAccueilData}
                    localAccueilState={localAccueilState}
                    setLocalAccueilState={setLocalAccueilState}
                />
            )}
            <div className="col_flex_aro">
                <div className="col_flex_aro accueil_para_text">
                    <div className="row_flex_aro with_img">
                        <div className="accueil_para_image">
                            <img
                                src={process.env.REACT_APP_API_IMAGES + props.dt.image}
                                alt="Bélen Rodriguez" />
                        </div>
                        <ParaText1Element
                            admin={props.admin}
                            setMarkUpdating={props.setMarkUpdating}
                            localAccueilState={localAccueilState}
                            setLocalAccueilState={setLocalAccueilState} />
                    </div>
                    <ParaText2Element
                        admin={props.admin}
                        setMarkUpdating={props.setMarkUpdating}
                        localAccueilState={localAccueilState}
                        setLocalAccueilState={setLocalAccueilState} />
                </div>
                {props.admin.mode && (
                    <UpdateOrDeletePara
                        admin={props.admin}
                        setAdmin={props.setAdmin}
                        markUpdating={props.markUpdating}
                        setMarkUpdating={props.setMarkUpdating}
                        setAccueilData={props.setAccueilData}
                        localAccueilState={localAccueilState}
                        setOldAccueilData={props.setOldAccueilData} />
                )}
            </div>
            <hr style={{
                    border: !props.admin.mode ? '1px solid lightgrey' : '5px solid blue',
                    margin: '60px 10vw 60px 10vw',
                }}></hr>
        </div>
  )
}
