import React from 'react';
import updateOnlineCard from '../request/services/updateOnlineCard';
import icons from '../../icons.js';

export default function ShowCards(props) {
  return (
    <div className="row_flex_aro servVoyCardContainer">
          {props.notice && props.notice.map((nt, index) => 
            <div key={'showCards' + index} hidden={!props.admin.mode && !nt.online}>
              {((!props.admin.mode && nt.online === 1) || props.admin.mode) && (
                <div
                  key={'notice' + index}
                  style={{background: nt.online ? 'white' : '#cb7b6e'}}
                  className="servVoyCardContent col_flex_aro noselect">
                  <div className="servVoyCardIndex" style={{color: nt.online ? 'white' : 'red'}}>
                    <span style={{position: 'relative', top: '-15px'}}>
                      {nt.online ? index + 1 : "X"}
                    </span>
                  </div>
                  {props.admin.mode && (
                    <>
                      <div
                        className="servVoyCardOnline"
                        onClick={() => {
                          updateOnlineCard(props.subject, props.admin, props.setAdmin, nt, props.setNotice)
                        }}>
                        <span style={{color: nt.online ? 'blue' : 'red'}}>
                          {nt.online ? 'En ligne' : 'Hors ligne'}
                        </span>
                      </div>
                      <div
                        className="servVoyCardadminEdit"
                        onClick={() => props.setEditCard({
                          state: true,
                          index: index,
                          card: nt,
                        })}>
                        {icons.edit_icon}
                      </div>
                    </>
                  )}
                  {nt.title.length > 0 && (
                    <div className="row_flex_aro servVoyCardTitle">
                      {nt.title}
                    </div>
                  )}
                  {nt.text_1.length > 0 && (
                    <div className="row_flex_aro servVoyCardText">
                      <p><span style={{marginLeft: '15px'}}></span>{nt.text_1}</p>
                    </div>
                  )}
                  {nt.text_2.length > 0 && (
                    <div className="row_flex_aro servVoyCardText">
                      <p><span style={{marginLeft: '15px'}}></span>{nt.text_2}</p>
                    </div>
                  )}
                  {nt.postscriptum.length > 0 && (
                    <div className="row_flex_aro servVoyCardPs">
                      <p className="servVoyCardPs">{nt.postscriptum}</p>
                    </div>
                )}
                </div>
              )}
            </div>
          )}
        </div>
  )
}