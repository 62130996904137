import axios from 'axios';
    
async function updateOnlineFormule(
        page,
        admin,
        setAdmin,
        frm,
        setFormule,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            services: 'updateOnlineFormule',
            page: page,
            email: admin.email,
            api_identifier: admin.api_identifier,
            card_id: frm.id,
            card_online: frm.online,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setFormule(resp.data.Formules);
            // if (page === 'Voyages') {
            //     setFormule(resp.data.voyagesArticlesFormules);
            // }
            // if (page === 'Mariages') {
            //     setFormule(resp.data.mariagesArticlesFormules);
            // }
            // if (page === 'Locations') {
            //     setFormule(resp.data.locationsArticlesFormules);
            // }
            // if (page === 'Autres') {
            //     setFormule(resp.data.autresArticlesFormules);
            // }
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })}
    )
}

export default updateOnlineFormule;