import axios from 'axios';
    
async function getVoyagesData(
    admin,
    setHeaderText,
    setNotice,
    setFormule,
    setAnswer,
    setDispHeaderTextTitle,
    setDispNoticeTitle,
    setDispFormuleTitle) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            services: 'getVoyagesData',
            api_identifier: admin.api_identifier,
            email: admin.email,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            let not = resp.data.Notices.filter(not => not.online === 1);
            setDispNoticeTitle(not.length > 0 ? true : false);

            let frm = resp.data.Formules.filter(frm => frm.online === 1);
            setDispFormuleTitle(frm.length > 0 ? true : false);

            let htx = resp.data.HeaderText;
            setDispHeaderTextTitle(htx !== '' ? true : false);
            
            setHeaderText(resp.data.HeaderText);
            setNotice(resp.data.Notices);
            setFormule(resp.data.Formules);
            setAnswer(resp.data.Answers);
        } else {
            setNotice([]);
        }
    }).catch((e) => setNotice([]))
}

export default getVoyagesData; 