const resumMenuWithScreenWidth = screenWidth => {
  if (screenWidth <= 600) {
    let items = document.querySelectorAll('.menuItem');
    items.forEach(item => {
      item.style = 'transform: scaleY(0)';
    });
    document.querySelector('.menuResum').style = 'transform: scale(1)';
  } else {
    document.querySelector('.menuResum').style = 'transform: scale(0)';
  }
};
export default resumMenuWithScreenWidth;