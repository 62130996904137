import axios from 'axios';
    
async function deleteBankImage(
        admin,
        bank,
        img,
        setFond_F2,
        setFond_F3,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            comment: 'deleteImgBank',
            email: admin.email,
            api_identifier: admin.api_identifier,
            bank: bank,
            image: img,
            onlineComment: 1,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) { 
            if (resp.data.message === 'Image supprimée') {
                setFond_F2(resp.data.Image_F2);
                setFond_F3(resp.data.Image_F3);
            }
        }
    }).catch(() =>  console.log('error'))
}

export default deleteBankImage; 