const reducSecondaryMenu = () => {
    setTimeout(() => {
    if (document.querySelector('.serviceMenu')) {
      document.querySelector('.serviceMenu').style.height = '0';
    }
    if(document.querySelector('.plusMenu')) {
      document.querySelector('.plusMenu').style.height = '0';
    }
  }, 10)
};
export default reducSecondaryMenu;