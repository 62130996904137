import axios from 'axios';
    
async function getAccueilData(admin, setAccueilData, setOldAccueilData) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            accueil: 'getData',
            api_identifier: admin.api_identifier,
            email: admin.email,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setAccueilData(resp.data.accueilData);
            setOldAccueilData(resp.data.oldAccueilData);
        } else {
            setAccueilData([]);
        }
    }).catch(() => setAccueilData([]))
}

export default getAccueilData; 