const expandNavBar = () => {
    document.querySelector('.menuResum').classList = 'menuResum reducResum';
    let items = document.querySelectorAll('.menuItem');
    items.forEach(item => {
        item.classList = 'col_flex_bet menuItem expandItem';
        item.style = 'transform: scaleY(1)';
    });
    setTimeout(
        () => document.querySelector('.menuResum').style = 'transform: scale(0)',
        400,
    );
    setTimeout(() => {
        document.querySelector('.menuMobilMarker').style.background = 'black';
        if (document.querySelector('.serviceMenu')) {
            document.querySelector('.serviceMenu').style.height = 'var(--servicesMenuHeight)';
        }
        if(document.querySelector('.plusMenu')) {
            document.querySelector('.plusMenu').style.height = 'var(--plusMenuHeight)';
        }
        if(document.querySelector('.menuList1')) {
            document.querySelector('.menuList1').style = 'z-index: 200';
        }
        if(document.querySelector('.menuList2')) {
            document.querySelector('.menuList2').style = 'z-index: -1';
        }
        },
        800,
    );
};
export default expandNavBar;