import axios from 'axios';
    
async function setFormuleContent(
        subject,
        admin,
        setAdmin,
        editFormule,
        setEditFormule,
        setFormule,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            services: subject,
            email: admin.email,
            api_identifier: admin.api_identifier,
            frm_id: editFormule.formule.id,
            frm_title: editFormule.formule.title,
            frm_text_1: editFormule.formule.text_1,
            frm_text_2: editFormule.formule.text_2,
            frm_image_place: editFormule.formule.image_place,
            frm_image_bg: editFormule.formule.image_bg,
            frm_image_text_color: editFormule.formule.image_text_color,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setFormule(resp.data.Formules);
            // if (subject === 'updateVoyagesFormules') {
            //     setFormule(resp.data.voyagesArticlesFormules);
            // }
            // if (subject === 'updateMariagesFormules') {
            //     setFormule(resp.data.mariagesArticlesFormules);
            // }
            // if (subject === 'updateLocationsFormules') {
            //     setFormule(resp.data.locationsArticlesFormules);
            // }
            // if (subject === 'updateAutresFormules') {
            //     setFormule(resp.data.autresArticlesFormules);
            // }
            setEditFormule({...editFormule, state: false})
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })}
    )
}

export default setFormuleContent;