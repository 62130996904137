import React from 'react';

export default function ConnectionForm(props) {
    return (
        <div className="modalContainer row_flex_aro dispModal">
          <div className="modalContent col_flex_aro">
            <div className="closeButDiv">
              <span onClick={() => {
                  document.querySelector('.modalContainer').classList =
                    'modalContainer row_flex_aro hideModal';
                  setTimeout(() =>
                    props.setAdmin({
                      mode: false,
                      dispPwdInput: false,
                      email: '',
                      pwd: '',
                      seePwd: false,
                      api_identifier: '',
                    }),
                    380,
                  )
                }}>
                X
              </span>
            </div>
            <div style={{marginTop: '100px'}}>
              <h3>Entrez en mode administrateur</h3>
            </div>
            <div className="col_flex_aro">
              <label htmlFor="emailValue noselect">Votre email :</label>
              <input
                type="text"
                name="email"
                id="emailValue"
                className="inputStyle_1"
                onChange={(e) => props.setAdmin({...props.admin, email: e.target.value})}
                value={props.admin.email} />
            </div>
            <div className="col_flex_aro noselect" style={{marginBottom: '100px'}}>
              <label htmlFor="passwordValue">Votre mot de passe : 
              {!props.admin.seePwd ? (
                <span
                  style={{position: 'relative', top: '8px', left: '20px', color: 'orange'}}
                  onClick={() => props.setAdmin({...props.admin, seePwd: true})}>
                  {props.icons.hide_value_icon}
                </span>
              ) : (
                <span
                  style={{position: 'relative', top: '8px', left: '20px', color: 'lightgreen'}}
                  onClick={() => props.setAdmin({...props.admin, seePwd: false})}>
                  {props.icons.see_value_icon}
                </span>
              )}
              </label>
              <input
                type={props.admin.seePwd ? "password" : "text"}
                name="password"
                id="passwordValue"
                className="inputStyle_1"
                onChange={(e) => props.setAdmin({...props.admin, pwd: e.target.value})}
                value={props.admin.pwd} />
            </div>
            <div className="row_flex_aro" style={{marginBottom: '100px'}}>
              <button
                type="button"
                className="butStyle_1"
                style={{background: 'lightgreen'}}
                onClick={() => props.setIdentificationAdmin(props.admin, props.setAdmin)}>Valider</button>
              <button
                type="reset"
                className="butStyle_1"
                onClick={() => {
                    props.setAdmin({
                      mode: false,
                      dispPwdInput: true,
                      email: '',
                      pwd: '',
                      seePwd: false,
                      api_identifier: '',
                    })
                }}
                style={{background: 'orange', marginLeft: '20px'}}>Reset</button>
            </div>
          </div>
        </div>
    )
}
