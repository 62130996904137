import React from 'react';

import setOnlineComment from '../request/comment/setOnlineComment';
import sendConfirmationMail from '../request/comment/sendConfirmationMail';

export default function CommentDisplaying(props) {
    const displayQuote = (img, altText) => {
        return (
            <img
                className="imageBank"
                style={{
                    maxWidth: window.screen.width > 650 ? '100px' : '40px',
                    border: 'none',
                }}
                src={process.env.REACT_APP_API_COMMENT_IMAGES + '/' + img}
                alt={altText} />
    )};
    const changeOnlineComment = (comment) => {
        setOnlineComment(
            props.admin,
            comment.id,
            !comment.online,                            
            props.commentData,
            props.setCommentData,
    )};
    const displayTitle = (comment) => {
        return (
            <div className="row_flex_aro">
                <h4
                    className="temTitle"
                    style={{color: comment.color}}>
                    {comment.title !== ''
                    ? comment.title
                    : <span style={{paddingRight: '10vw'}}>Témoignage</span>}
                    </h4>
            </div>
    )};
    const displayText = (comment) => {
        return (
            <div className="row_flex_aro">
                <p
                    className="temText"
                    style={{color: comment.color}}>
                    <span style={{marginLeft: '30px'}}></span>
                    {comment.text}
                </p>
            </div>
    )};
    const displayF2F3Image = (comment) => {
        return (
            <div>
                <img
                    className="temImageF3"
                    src={process.env.REACT_APP_API_COMMENT_IMAGES + comment.image}
                    alt={"Témoignage de " + comment.editor_pseudo} />
            </div>
    )};
    const displayEditor = (comment) => {
        return (
            <p
                className="temEditor"
                style={{color: comment.color}}>
                {comment.editor_pseudo !== ''
                    ? comment.editor_pseudo
                    : 'Client anonyme'}
            </p>
    )};
    const onlineButton = (comment) => {
        return (
            <button
                className="butStyle_1"
                id={'commentId' + comment.id}
                onClick={() => changeOnlineComment(comment)}
                style={{
                    zIndex: '2',
                    position: 'relative',
                    top: comment.format === 1 ? '-20px' : '0',
                    background: !comment.online ? '#b18080' : 'lightgreen',
                    margin: '0',
                }}>
                {!comment.online ? 'Hors ligne' : 'En ligne'}
            </button>
    )};
    const sendMailButton = (comment) => {
        return (
            <button
                className="butStyle_1 sendMailBut"
                onClick={() => 
                    sendConfirmationMail(
                    props.admin,
                    comment.id,
                    )
                }
                style={{top: comment.format !== 1 ? '10px' : '-10px'}}>
                Envoyer mail
                <br></br>
                <span style={{fontSize: '10px'}}>
                    {comment.email.length < 20
                        ? comment.email
                        : comment.email.substring(0, 20) + '...'}
                </span>
            </button>
    )};

    return (
        <>
            {props.commentData.map((comment, index) => (
                <div className="col_flex_aro commentContainer" key={"comment" + index}>
                    {comment.format < 2 && (
                        <div
                            className="col_flex_aro temContainer noselect"
                            style={{minHeight: '300px'}}>
                            {comment.format === 1 && (
                                <img
                                    className="temImageF2"
                                    src={process.env.REACT_APP_API_COMMENT_IMAGES + comment.image}
                                    alt={"Témoignage de " + comment.editor_pseudo} />
                            )}
                            {props.admin.mode && (
                                <div>
                                    {onlineButton(comment)}
                                    {comment.email !== '' && sendMailButton(comment)}
                                </div>
                            )}
                            {(comment.title !== '' || props.admin.mode) && (
                                <div className="row_flex_aro">
                                    {comment.format === 0 && displayQuote('openQuote.png', 'Ouvrir les guillemets')}
                                    {displayTitle(comment)}
                                </div>
                            )}
                            {comment.text !== '' && displayText(comment)}
                            <div className="row_flex_aro" style={{width: '100%'}}>
                                {displayEditor(comment)}
                                {comment.format === 0 && displayQuote('closeQuote.png', 'Fermer les guillemets')}
                            </div>
                        </div>
                    )}

                    {comment.format > 1 && (
                        <div className="row_flex_aro temContainer noselect">
                            {window.screen.width > 600 && comment.format === 2 &&
                                displayF2F3Image(comment)}
                            {window.screen.width <= 600 && comment.format > 1 && (
                                <img
                                    className="temImageF2"
                                    src={process.env.REACT_APP_API_COMMENT_IMAGES + comment.image}
                                    style={{maxWidth: '250px'}}
                                    alt={"Témoignage de " + comment.editor_pseudo} />
                            )}
                            <div>
                                {(comment.title !== '' || props.admin.mode) && (
                                    <div className="col_flex_aro">
                                        {props.admin.mode && (
                                            <div>
                                                {onlineButton(comment)}
                                                {comment.email !== '' && sendMailButton(comment)}
                                            </div>
                                        )}
                                        {displayTitle(comment)}
                                    </div>
                                )}
                                {comment.text !== '' && displayText(comment)}
                                <div className="row_flex_aro">
                                    {displayEditor(comment)}
                                </div>
                            </div>
                            {window.screen.width > 600 && comment.format === 3 && 
                                displayF2F3Image(comment)}
                        </div>
                    )}
                    <hr className="hrStyle"></hr>
                </div>
            ))}
        </>
    )
}
