import axios from 'axios';
    
async function getAProposData(admin, setAProposData, setOldAProposData) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            apropos: 'getData',
            api_identifier: admin.api_identifier,
            email: admin.email,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setAProposData(resp.data.Data);
            setOldAProposData(resp.data.OldData);
        } else {
            setAProposData([]);
        }
    }).catch((e) => setAProposData([]))
}

export default getAProposData; 