import React from 'react';
import updateAProposPara from '../request/aPropos/updateAProposPara'
import deleteAProposPara from '../request/aPropos/deleteAProposPara'

export default function UpdateOrDeletePara(props) {
  return (
    <div className="row_flex_aro" style={{width: '60vw', margin:'30px 0 0 20vw'}}>
            <button
                type="button"
                className="butStyle_1"
                style={{
                    background: 
                        (props.markUpdating.state && props.markUpdating.id === props.index)
                            ? 'lightgreen'
                            : 'green'}}
                onClick={() => 
                    updateAProposPara(
                        props.admin,
                        props.setAdmin,
                        props.setAProposData,
                        props.setOldAProposData,
                        props.aProposData[props.index],
                        props.setMarkUpdating,
                    )
                } >
                Update
            </button>
            <button
                type="button"
                className="butStyle_1"
                style={{background: 'orange'}}
                onClick={() => 
                    deleteAProposPara(
                        props.admin,
                        props.setAdmin,
                        props.setAProposData,
                        props.aProposData[props.index],
                        props.setOldAProposData,
                    )
                } >
                Delete
            </button>
        </div>
  )
}
