import axios from 'axios';
    
async function updateAccueilPara(
        admin,
        setAdmin,
        setAccueilData,
        localAccueilState,
        setMarkUpdating,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            accueil: 'updatePara',
            email: admin.email,
            api_identifier: admin.api_identifier,
            para_id: localAccueilState.id,
            para_title: localAccueilState.title,
            para_text_1: localAccueilState.text_1,
            para_text_2: localAccueilState.text_2,
            para_image_place: localAccueilState.image_place,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setAccueilData(resp.data.accueilData);
            setMarkUpdating({state: false, id: 0});
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })}
    )
}

export default updateAccueilPara;