import axios from 'axios';
    
async function getLogs(admin, setAdmin, setLogs, offset) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            logs: 'getLogs',
            api_identifier: admin.api_identifier,
            email: admin.email,
            offset: offset,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setLogs(resp.data.logs);
        } else {
            setLogs([]);
        }
    }).catch(() => setLogs([]))
}

export default getLogs; 