import React, {useState, useEffect} from 'react';
import CardAdminForm from './servicesLocationsComponents/CardAdminForm';
import HeaderText from './servicesComponents/HeaderText';
import ShowCards from './servicesComponents/ShowCards';
import ShowFormules from './servicesComponents/ShowFormules';
import AnswerList from './servicesComponents/AnswerList';
import FormuleAdminForm from './servicesLocationsComponents/FormuleAdminForm';
import PageHeader from './PageHeader';
import {Link} from 'react-router-dom';
import Footer from './Footer';
import ArrowUp from './ArrowUp';
import getLocationsData from './request/services/getLocationsData';
import addHeaderTextPara from './request/services/addHeaderTextPara';
import deleteHeaderTextPara from './request/services/deleteHeaderTextPara';
import updateHeaderTextPara from './request/services/updateHeaderTextPara';
import icons from '../icons.js';
import '../style/servicesVoyageStyle.css';

export default function ServicesLocations(props) {
  const [headerText, setHeaderText] = useState([]);
  const [notice, setNotice] = useState([]);
  const [formule, setFormule] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [editFormule, setEditFormule] = useState({
    state: false,
    index: 0,
    formule: {},
  });
  const [changeImg, setChangeImg] = useState(false);
  const [editCard, setEditCard] = useState({state: false, index: 0});
  const [dispHeaderTextTitle, setDispHeaderTextTitle] = useState(false);
  const [dispNoticeTitle, setDispNoticeTitle] = useState(false);
  const [dispFormuleTitle, setDispFormuleTitle] = useState(false);

  useEffect(() => {
      getLocationsData(
        props.admin,
        setHeaderText,
        setNotice,
        setFormule,
        setAnswer,
        setDispHeaderTextTitle,
        setDispNoticeTitle,
        setDispFormuleTitle,
      );

    if (!props.admin.mode) {
      window.scrollTo(0,0);
      let not = notice.filter((nt, index) => nt.online);
      setNotice(not)
    }

    return;
  }, [props.admin])

  return (
    <div className="col_flex_aro dispPage" style={{paddingTop: '100px'}}>
        <PageHeader
          admin={props.admin}
          setAdmin={props.setAdmin}
          changeImg={changeImg}
          setChangeImg={setChangeImg}
          setData={false}
          setFormule={setFormule}
          setNotice={setNotice}
          imageName="img_locations_1_1500x540.png"
          imageId="LocationsTopImg"
          updateOrder="LocationsTopImg"
          pageTitleDisplay={true}
          pageTitle="Locations" />
        {notice && notice.length === 0
          && formule && formule.length === 0 && (
          <div className="row_flex_aro">
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
          </div>
        )}
        {dispHeaderTextTitle && (
          <HeaderText  
            admin={props.admin}
            setAdmin={props.setAdmin}
            headerText={headerText}
            setHeaderText={setHeaderText}
            icons={icons}
            subject="Locations"
            addHeaderTextPara={addHeaderTextPara}
            deleteHeaderTextPara={deleteHeaderTextPara}
            updateHeaderTextPara={updateHeaderTextPara} />
        )}
        {((headerText && headerText.length > 0) || props.admin.mode) && (
          <hr className="hrStyle" style= {{marginTop: '80px'}}></hr>
        )}
        {(dispNoticeTitle || props.admin.mode) && (
          <div>
            <h3 className="h3SerVoyTitle noselect">Le fonctionnement</h3>
          </div>
        )}
        <ShowCards 
          admin={props.admin}
          setAdmin={props.setAdmin}
          notice={notice}
          setNotice={setNotice}
          icons={icons}
          editCard={editCard}
          setEditCard={setEditCard}
          subject="Locations" />
        {props.admin.mode && editCard.state && (
          <CardAdminForm
            admin={props.admin}
            setAdmin={props.setAdmin}
            editCard={editCard}
            setEditCard={setEditCard}
            setNotice={setNotice} />
        )}
        {(dispNoticeTitle || props.admin.mode) && (
          <hr className="hrStyle" style={{marginTop: '100px'}}></hr>
        ) }
        {(dispFormuleTitle || props.admin.mode) && (
          <div>
            <h3 className="h3SerVoyTitle noselect">Formules</h3>
          </div>
        )}
        <ShowFormules
          formule={formule}
          setFormule={setFormule}
          admin={props.admin}
          subject="Locations"
          setEditFormule={setEditFormule} />
          {(props.admin.mode && editFormule.state) && (
            <FormuleAdminForm
              admin={props.admin}
              setAdmin={props.setAdmin}
              formule={formule}
              setFormule={setFormule}
              editFormule={editFormule}
              setEditFormule={setEditFormule} />
          )}
        {((answer && answer.length > 0 && answer[0].title.length > 0)
          || props.admin.mode) && (
          <>
            <hr className="hrStyle"></hr>
            <AnswerList
              admin={props.admin}
              subject="Locations"
              answer={answer}
              setAnswer={setAnswer} />
          </>
        )}
        <hr className="hrStyle" style={{marginTop: '50px'}}></hr>
        <div
          className="row_flex_aro"
          style={{position: 'relative', marginBottom: '20px'}}>
          <Link
            to={'/Contact'}
            style={{textDecoration: 'none'}}
            onClick={() => props.setMenu({...props.menu, menu2: 'Contact'})}>
            <img
              className="clikableImage"
              src={process.env.REACT_APP_API_IMAGES + '/contactImage.png'}
              alt="Lien vers la page autres-événements"/>
            <div
              className="textOnImage"
              id="markContact">Contact</div>
          </Link>
        </div>
        <hr className="hrStyle"></hr>
      <Footer />
      <ArrowUp />
    </div>
  )
}
