import React, {useState} from 'react';
import updateAnswer from '../request/services/updateAnswer'
import '../../style/answerStyle.css';

export default function AnswerList(props) {
    const [refresh, setrefresh] = useState(0);
    return (
        <div>
            <div style={{marginBottom: '50px', textAlign: 'center'}}>
                <h3 className={window.screen.width > 700
                    ? "h3Title_2 noselect"
                    : "col_flex_aro h3Title_2 noselect"}>
                    {props.subject === 'Voyages' && (
                        <div>
                            <span className="h3SerVoyTitle_2">Pourquoi prendre une </span>
                            <span className="h3SerVoyTitle_2">organisatrice de voyage?</span>
                        </div>
                    )}
                    {props.subject === 'Mariages' && (
                        <div>
                            <span className="h3SerVoyTitle_2">Pourquoi prendre une </span>
                            <span className="h3SerVoyTitle_2">organisatrice de mariage?</span>
                        </div>
                    )}
                    {props.subject === 'Locations' && (
                        <div>
                            <span className="h3SerVoyTitle_2">Pourquoi prendre une </span>
                            <span className="h3SerVoyTitle_2">location de décoration?</span>
                        </div>
                    )}
                    {props.subject === 'Autres' && (
                        <div>
                            <span className="h3SerVoyTitle_2">Pourquoi prendre une </span>
                            <span className="h3SerVoyTitle_2">organisatrice d'événement?</span>
                        </div>
                    )}
                </h3>
            </div>
            <div key="uniq2" className="col_flex_aro">
                {!props.admin.mode
                    && props.answer
                    && props.answer.length > 0
                    && props.answer.map((anw, index) => (
                    <div key={'answerA' + index}>
                        {anw.title !== '' && (
                            <div className="answerContainer">
                                <div className="col_flex_bet">
                                    <div className="row_flex_bet" style={{width: 'var(--answerContainerWidth)'}}>
                                        <p className="answerIndex">
                                            {'0' + (index + 1)}
                                        </p>
                                        <div style={{width: '80vw'}}>
                                            <p className="answerTitle">{anw.title}</p>
                                        </div>
                                    </div>
                                    <p
                                        type="text"
                                        className="answerDesc"
                                        onChange={(e) => {
                                            let newAnswer = props.answer;
                                            if (e.target.value.length < 255) {
                                                newAnswer[index].text = e.target.value;
                                            } else {
                                                newAnswer[index].text = e.target.value.substring(0, 255);
                                            }
                                            props.setAnswer(newAnswer);
                                            setrefresh(refresh + 1);
                                        }}>
                                            <span style={{marginLeft: '30px'}}></span>
                                            {anw.text}
                                        </p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                {props.admin.mode
                    && props.answer
                    && props.answer.length > 0
                    && props.answer.map((anw, index) => (
                    <div key={'answerAd' + index} className="answerContainerAdmin">
                        <div>
                            <p className="answerIndex">{'0' + (index + 1)}</p>
                        </div>
                        <div>
                            <div className="col_flex_aro" style={{width: '60vw'}}>
                                <span
                                    style={{
                                        color: anw.title.length < 60 ? 'blue' : 'red',
                                        fontSize: '16px',
                                        }}> 
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{anw.title.length} / 60 caractères
                                </span>
                                <textarea
                                    type="text"
                                    className="answerTitle answerInput"
                                    rows="1"
                                    style={{width: '50vw'}}
                                    onChange={(e) => {
                                        let newAnswer = props.answer;
                                        if (e.target.value.length < 60) {
                                            newAnswer[index].title = e.target.value;
                                            props.setAnswer(newAnswer);
                                            document.querySelector('#updateAnswer' + index).style.background = 'lightgreen';
                                        } else {
                                            newAnswer[index].title = e.target.value.substring(0, 60);
                                        }
                                        setrefresh(refresh + 1);
                                    }}
                                    value={anw.title}></textarea>
                            </div>
                            <div className="col_flex_aro" style={{marginRight: '20vw'}}>
                                <div className="answerUpdateBut">
                                    <span
                                        style={{
                                            color: anw.text.length < 255 ? 'blue' : 'red',
                                            fontSize: '16px',
                                            }}> 
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{anw.text.length} / 255 caractères
                                    </span>
                                    <button
                                        className="butStyle_1"
                                        id={"updateAnswer" + index}
                                        style={{
                                            transform: 'scale(0.5)',
                                            background: 'green',
                                        }}
                                        onClick={() => {
                                            updateAnswer(
                                                props.subject,
                                                index,
                                                props.admin,
                                                props.setAdmin,
                                                anw,
                                                props.setAnswer,
                                            );
                                        }}>UPDATE</button>
                                </div>
                                <textarea
                                    type="text"
                                    className="answerDesc"
                                    rows="5"
                                    style={{width: '60vw'}}
                                    onChange={(e) => {
                                        let newAnswer = props.answer;
                                        if (e.target.value.length < 255) {
                                            newAnswer[index].text = e.target.value;
                                            props.setAnswer(newAnswer);
                                            document.querySelector('#updateAnswer' + index).style.background = 'lightgreen';
                                        } else {
                                            newAnswer[index].text = e.target.value.substring(0, 255);
                                        }
                                        setrefresh(refresh + 1);
                                    }}
                                    value={anw.text}></textarea>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
