import React from 'react';
import updateTopImg from './request/updateTopImg';
import icons from '../icons';

export default function PageHeader (props) {

    return (
        <>
            <div className="accueil_img1_div" style={{marginBottom: '60px'}}>
                <div className="noselect">
                    <img
                    className="accueil_img1"
                    id={props.imageId}
                    src={process.env.REACT_APP_API_IMAGES + props.imageName}
                    alt="Décor de l'entête de la page accueil" />
                    {props.pageTitle !== 'Galerie' && props.admin.mode && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderLeft: '1px solid black',
                            borderBottom: '1px solid black',
                            borderRight: '1px solid black',
                        }}>
                        <span
                            onClick={() => props.setChangeImg(!props.changeImg)}
                            style={{
                                zIndex: '3000',
                                padding: '0 10px 5px 10px',
                                animation: 'showEditIcon 2s linear infinite',
                                }}>
                            {icons.edit_icon}
                        </span>
                        {props.changeImg && (
                        <div style={{zIndex: '3000'}}>
                            <label
                                style={{zIndex: '3000', fontSize: '18px', fontWeight: '600', margin: '4px 0 0 15px'}}
                                htmlFor="changeAccueilTopImg">
                                Changer l'image ci-dessus (1500px / 540px)
                            </label>
                            <input
                                type="file"
                                id="changeAccueilTopImg"
                                className="fileInputBut"
                                style={{margin: '6px 0 0 15px', height: '20px'}}
                                onChange={(e) => {
                                    updateTopImg(
                                        e.target.files[0],
                                        props.admin,
                                        props.setAdmin,
                                        props.setData,
                                        props.setNotice,
                                        props.setFormule,
                                        props.setChangeImg,
                                        props.updateOrder,
                                        props.imageName,
                                    );
                                    setTimeout(() => 
                                    document.querySelector('#' + props.imageId).src =
                                        process.env.REACT_APP_API_IMAGES +
                                        props.imageName + "?" +
                                        Date.now(),
                                    2000,
                                    );
                                }} />
                            </div>
                        )}
                    </div>
                    )}
                </div>
                {props.pageTitleDisplay && (
                    <div id="#pageHeaderTitle" className="col_flex_aro accueil_text1_div noselect">
                        <div className="h2ServVoyPositionDiv">
                            <h2 className="h2ServVoyPosition">{props.pageTitle}</h2>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
