import React from 'react';
import CommentValidation from './CommentValidation';

export default function CommentEditorFormat1(props) {
    return (
        <div className="col_flex_aro" style={{margin: '20px 0'}}>
            <div className="col_flex_aro">
                <div className="row_flex_aro" style={{maxWidth: '800px'}}>
                    <img
                        className="imageBank"
                        style={{
                            maxWidth: window.screen.width > 650 ? '100px' : '40px',
                            border: 'none',
                        }}
                        src={process.env.REACT_APP_API_COMMENT_IMAGES + '/openQuote.png'}
                        alt="Ouvrir les guillemets" />
                    {!props.newComment.visu && (
                        <div className="col_flex_aro commentContentInput">
                            <div className="row_flex_aro">
                                <label
                                    htmlFor="commentTitle"
                                    className="commentInputLabel">Titre</label>
                                <span style={{
                                        fontSize: window.screen.width > 650 ? '12px' : '10px',
                                        marginLeft: '50px',
                                        background: 'white',
                                        color: props.newComment.title.length < 50
                                        ? 'blue'
                                        : 'red'
                                    }}>
                                    {props.newComment.title.length + "/ 50 caractères"}
                                </span>
                            </div>
                            <input
                                type="text"
                                className="commentInputStyle"
                                name="commentTitle"
                                style={{
                                    width: '400px',
                                    maxWidth: '50vw',
                                    background: 'rgba(200, 200, 200, 0.2)',
                                    color: props.newComment.color,
                                }}
                                id="fmt2_title"
                                onFocus={() => 
                                    document.querySelector("#fmt2_title").style.background =
                                        'rgb(255, 255, 242)'
                                }
                                onBlur={() => 
                                    document.querySelector("#fmt2_title").style.background =
                                        'rgba(200, 200, 200, 0.5)'
                                }
                                size="30"
                                onChange={(e) => {
                                    if (e.target.value.length <= 50) {
                                        props.setNewComment({
                                            ...props.newComment,
                                            title: e.target.value,
                                            });
                                    }
                                    }}
                                value={props.newComment.title}
                                placeholder="Ici votre titre" />
                        </div>
                    )}
                    {props.newComment.visu && (
                        <div className="commentTitleDivPrevisu">
                            <h4
                                className="commentTitle"
                                style={{
                                    color: props.newComment.color,
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                }}>{props.newComment.title}</h4>
                        </div>
                    )}
                </div>
                <div className="col_flex_aro commentContentInput">
                    {!props.newComment.visu && (
                        <>
                            <div className="row_flex_aro">
                                <label
                                    htmlFor="commentText"
                                    className="commentInputLabel">Votre témoignage</label>
                                <span style={{
                                        fontSize: window.screen.width > 650 ? '12px' : '10px',
                                        marginLeft: '50px',
                                        background: 'white',
                                        color: props.newComment.comment.length < 500
                                        ? 'blue'
                                        : 'red'
                                    }}>
                                    {props.newComment.comment.length + "/ 500 caractères"}
                                </span>
                            </div>
                            <textarea
                                type="text"
                                className="commentInputStyle"
                                name="commentText"
                                style={{
                                    width: '80vw',
                                    background:
                                        !props.newComment.error
                                            ? 'rgba(200, 200, 200, 0.2)'
                                            : '#f59191',
                                    color: props.newComment.color,
                                    textAlign: 'justify',
                                }}
                                id="fmt2_text"
                                onFocus={() => 
                                    document.querySelector("#fmt2_text").style.background =
                                        'rgb(255, 255, 242)'
                                }
                                onBlur={() => 
                                    document.querySelector("#fmt2_text").style.background =
                                        'rgba(200, 200, 200, 0.5)'
                                }
                                rows="8"
                                onChange={(e) => {
                                    if (e.target.value.length <= 500) {
                                        props.setNewComment({
                                            ...props.newComment,
                                            error: false,
                                            comment: e.target.value,
                                        });
                                    }
                                    }}
                                value={props.newComment.comment}
                                placeholder="Rédigez votre témoignage"></textarea>
                            </>
                        )}
                        {props.newComment.visu && (
                            <div className="commentTextDivPrevisu row_flex_aro">
                                <p
                                    className="commentText"
                                    style={{
                                        color: props.newComment.color,
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                    }}>
                                    <span style={{marginLeft: '30px'}}></span>
                                    {props.newComment.comment}
                                </p>
                            </div>
                        )}
                </div>
                {!props.newComment.visu && (
                    <>
                        <div className="row_flex_aro">
                            <div className="col_flex_aro">
                                <div className="row_flex_aro">
                                    <label
                                        htmlFor="commentPseudo"
                                        className="commentInputLabel">Votre nom / pseudo</label>
                                    <span style={{
                                            fontSize: '12px',
                                            marginLeft: '50px',
                                            background: 'white',
                                            color: props.newComment.name.length < 30
                                            ? 'blue'
                                            : 'red'
                                        }}>
                                        {props.newComment.name.length + "/ 30 caractères"}
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="commentInputStyle"
                                    name="commentPseudo"
                                    style={{
                                        width: '320px',
                                        maxWidth: '60vw',
                                        background: 'rgba(200, 200, 200, 0.2)',
                                        color: props.newComment.color,
                                    }}
                                    id="fmt2_pseudo"
                                    onFocus={() => 
                                        document.querySelector("#fmt2_pseudo").style.background =
                                            'rgb(255, 255, 242)'
                                    }
                                    onBlur={() => 
                                        document.querySelector("#fmt2_pseudo").style.background =
                                            'rgba(200, 200, 200, 0.5)'
                                    }
                                    onChange={(e) => {
                                        if (e.target.value.length <= 30) {
                                            props.setNewComment({
                                                ...props.newComment,
                                                name: e.target.value,
                                                })
                                        }}}
                                    value={props.newComment.name}
                                    placeholder="Ici votre nom ou pseudo" />
                            </div>
                            <img
                                className="imageQuote"
                                style={{
                                    maxWidth: window.screen.width > 650 ? '100px' : '40px',
                                    border: 'none',
                                }}
                                src={process.env.REACT_APP_API_COMMENT_IMAGES + '/closeQuote.png'}
                                alt="Fermer les guillemets" />
                        </div>
                    </>
                )}
                {props.newComment.visu && (
                    <div className="row_flex_aro commentEditorDiv" style={{width: '80vw'}}>
                        <p
                            className="commentEditor_fond_F1"
                            style={{
                                color: props.newComment.color,
                                borderRadius: '4px',
                            }}>
                            {props.newComment.name}
                        </p>
                        <img
                            className="imageQuote"
                            style={{
                                maxWidth: window.screen.width > 650 ? '100px' : '40px',
                                border: 'none',
                            }}
                            src={process.env.REACT_APP_API_COMMENT_IMAGES + '/closeQuote.png'}
                            alt="Fermer les guillemets" />
                    </div>
                )}
            </div>
            
            <CommentValidation
                newComment={props.newComment}
                setNewComment={props.setNewComment}
                commentState={props.commentState}
                setCommentState={props.setCommentState} />
        </div>
  )
}
