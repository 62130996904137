import axios from 'axios';
    
async function setIdentificationAdmin(admin, setAdmin) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            admin: 'getAuth',
            api_identifier: admin.api_identifier,
            email: admin.email,
            password: admin.pwd
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            document.querySelector('.modalContainer').classList =
                'modalContainer row_flex_aro hideModal';
            setTimeout(() =>
                setAdmin({
                    ...admin,
                    mode: (resp.data.api_identifier !== '' && resp.data.api_identifier.length === 25),
                    dispPwdInput: false,
                    pwd: '',
                    seePwd: false,
                    api_identifier: resp.data.api_identifier,
                    message: resp.data.api_identifier !== ''
                        ? 'Vous êtes identifié.'
                        : 'Erreur d\'identifiants',
                }),
                380,
            )
        } else {
            setTimeout(() => 
                setAdmin({
                    ...admin,
                    mode: false,
                    dispPwdInput: false,
                    pwd: "",
                    api_identifier: '',
                    seePwd: false,
                    message: 'Erreur d\'identifiants ou de réseau',
                }),
            2000,
        )
        }
    }).catch(() =>
        setAdmin({
            ...admin,
            mode: false,
            dispPwdInput: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })
    )
}

export default setIdentificationAdmin;