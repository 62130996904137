import React, {useState} from 'react';
import setCardContent from '../request/services/setCardContent';
import icons from '../../icons.js';

export default function CardAdminForm(props) {
    const [markModification, setMarkModification] = useState(false);

    return (
        <div className="modalCardContainer row_flex_aro">
            <div className="servVoyCardContent modalCardContent">
                <div className="servVoyCardIndex">
                    {props.editCard.index + 1}
                </div>
                <div
                    className="servVoyCardadminEdit"
                    onClick={() => props.setEditCard({
                        state: false,
                        index: 0,
                        card: null,
                    })}>
                    <span style={{color: 'red'}}>{icons.rewind_icon}</span>
                </div>
                {markModification && (
                    <div
                        className="servVoyCardadminEdit"
                        style={{left: '240px', background: 'black', borderRadius: '6px'}}
                        onClick={() => {
                            setMarkModification(false);
                            setCardContent(
                                'updateMariagesCard',
                                props.admin,
                                props.setAdmin,
                                props.editCard,
                                props.setEditCard,
                                props.setNotice,
                            )
                            }}>
                        <span style={{color: 'lightgreen', padding: '4px 8px'}}>SAVE</span>
                    </div>
                )}
                <div className="col_flex_aro servVoyCardTitle">
                    <label htmlFor="cardTitle" style={{fontSize: '12px'}}>
                    Le titre
                    <span
                    style={{
                        color: props.editCard.card.title.length < 24 ? 'blue' : 'red',
                        fontSize: '16px',
                        }}> 
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.editCard.card.title.length} / 24 caractères
                    </span>
                    </label>
                    <input
                        type="text"
                        name="cardTitle"
                        size="18"
                        maxLength="24"
                        className="servVoyCardTitle"
                        style={{marginTop: '0', fontSize: '18px'}}
                        onChange={(e) => {
                            setMarkModification(true);
                            let newCard = props.editCard.card;
                            newCard.title = e.target.value;
                            props.setEditCard({...props.editCard, card: newCard})}
                            }
                        value={props.editCard.card.title}/>
                </div>
                <div className="col_flex_aro servVoyCardText">
                    <label htmlFor="cardTitle" style={{fontSize: '12px'}}>
                    Texte 1
                    <span
                    style={{
                        color: props.editCard.card.text_1.length < 400 ? 'blue' : 'red',
                        fontSize: '16px',
                        }}> 
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.editCard.card.text_1.length} / 400 caractères
                    </span>
                    </label>
                    <textarea
                        type="text"
                        name="cardTitle"
                        rows="4"
                        cols="24"
                        maxLength="400"
                        className="servVoyCardText"
                        style={{marginTop: '0', fontSize: '18px'}}
                        onChange={(e) => {
                            setMarkModification(true);
                            let newCard = props.editCard.card;
                            newCard.text_1 = e.target.value;
                            props.setEditCard({...props.editCard, card: newCard})}
                            }
                        value={props.editCard.card.text_1}></textarea>
                </div>
                <div className="col_flex_aro servVoyCardText">
                    <label htmlFor="cardTitle" style={{fontSize: '12px'}}>
                    Texte 2
                    <span
                    style={{
                        color: props.editCard.card.text_2.length < 124 ? 'blue' : 'red',
                        fontSize: '16px',
                        }}> 
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.editCard.card.text_2.length} / 124 caractères
                    </span>
                    </label>
                    <textarea
                        type="text"
                        name="cardTitle"
                        rows="4"
                        cols="24"
                        maxLength="124"
                        className="servVoyCardText"
                        style={{marginTop: '0', fontSize: '18px'}}
                        onChange={(e) => {
                            setMarkModification(true);
                            let newCard = props.editCard.card;
                            newCard.text_2 = e.target.value;
                            props.setEditCard({...props.editCard, card: newCard})}
                            }
                        value={props.editCard.card.text_2}></textarea>
                </div>
                <div className="col_flex_aro servVoyCardPs">
                    <label htmlFor="cardTitle" style={{fontSize: '12px'}}>
                    Postscriptum
                    <span
                    style={{
                        color: props.editCard.card.postscriptum.length < 24 ? 'blue' : 'red',
                        fontSize: '16px',
                        }}> 
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.editCard.card.postscriptum.length} / 24 caractères
                    </span>
                    </label>
                    <input
                        type="text"
                        name="cardTitle"
                        size="18"
                        maxLength="24"
                        className="servVoyCardTitle"
                        style={{marginTop: '0', fontSize: '18px'}}
                        onChange={(e) => {
                            setMarkModification(true);
                            let newCard = props.editCard.card;
                            newCard.postscriptum = e.target.value;
                            props.setEditCard({...props.editCard, card: newCard})}
                            }
                        value={props.editCard.card.postscriptum}/>
                </div>
            </div>
        </div>
    )
}
