
import axios from 'axios';
    
async function getGalleryPhoto(setPhoto) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            gallery: 'getPhoto',
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setPhoto(resp.data.GalleryPhoto);
        } else {
            setPhoto([]);
        }
    }).catch((e) => setPhoto([]))
}

export default getGalleryPhoto; 