import React, {useEffect} from 'react';

export default function ManageAProposPostsciptum(props) {

    useEffect(() => {
        if (props.newData === null){
            props.setNewData(props.aProposData[props.index]);
        }

        return;
    }, [])
    
    return (
        <div
            className="col_flex_aro apropos_para_text"
            style={{marginTop: '10px'}} >
            <span
                style={{
                    color: (props.aProposData[props.index].postscriptum.length) < 128 ? 'blue' : 'red',
                    fontSize: '14px',
                    fontFamily: 'consolas',
                    }}>
                Postscriptum : {props.aProposData[props.index].postscriptum.length} / 128 caractéres
            </span>
            <textarea
                className="accueil_para_text setInputFocus"
                cols={window.screen.width > 840 ? 60 : 30}
                rows="2"
                onChange={(e) => {
                    if (e.target.value.length <= 128) {
                        props.changeParaPostscriptum(e, props.index);
                    }
                }}
                value={props.aProposData[props.index].postscriptum}></textarea>
        </div>
    )
}
