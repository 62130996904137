import axios from 'axios';
    
async function updateOnlineUser(admin, user, setUsers) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            users: 'updateOnline',
            api_identifier: admin.api_identifier,
            email: admin.email,
            id: user.id,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setUsers(resp.data.users);
        } else {
            setUsers([]);
        }
    }).catch(() => setUsers([]))
}

export default updateOnlineUser; 