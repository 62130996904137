import React from 'react';
import updateOnlineFormule from '../request/services/updateOnlineFormule';
import icons from '../../icons';

export default function ShowFormules(props) {
    return (
        <div
            className="row_flex_aro"
            style={{
                flexFlow: 'wrap',
                marginTop: '50px',
            }}>
          {props.formule && props.formule.map((frm, index) => (
            <div key={'showCards' + index} hidden={!props.admin.mode && !frm.online}>
                    {((!props.admin.mode && frm.online === 1) || props.admin.mode) && (
                        <div
                            key={'formule' + index}
                            className={window.screen.width > 700
                            ? "row_flex_bet formuleContent"
                            : "col_flex_bet formuleContent"}
                            style={{background: frm.image_bg, position: 'relative'}}>
                        {(window.screen.width < 700 || frm.image_place === 'left') && (
                            <div style={{width: window.screen.width > 700 ? '50%' : '100%'}}>
                                <div className="row_flex_aro">
                                    <img
                                    className="formuleImage"
                                    src={process.env.REACT_APP_API_IMAGES + frm.image}
                                    alt={'formule numéro ' + index + 1} />
                                </div>
                            </div>
                        )}
                        <div className="col_flex_aro" style={{width: '50%', height: '100%'}}>
                            {frm.title.length > 0 && (
                                <div
                                    className="servVoyFormTitle noselect"
                                    style={{
                                        padding: '0 12px',
                                        textAlign: frm.image_place === 'left'
                                            ? window.screen.width > 700 ? 'right' : 'left'
                                            : 'left',
                                        }}>
                                        <span style={{
                                            color: frm.image_text_color,
                                            margin: frm.image_place === 'right'
                                                ? ' 0 0 0 15px'
                                                : window.screen.width > 700 ? '0 15px 0 0' : ' 0 0 0 15px'
                                            }}>
                                        {frm.title}
                                        </span>
                                </div>
                            )}
                            {frm.text_1.length > 0 && (
                                <div
                                    className="row_flex_aro servVoyFormText1 noselect">
                                    <p style={{padding: '0 12px', color: frm.image_text_color}}>
                                        <span style={{marginLeft: '15px'}}></span>
                                        {frm.text_1}
                                    </p>
                                </div>
                            )}
                            {frm.text_2.length > 0 && (
                                <div className="row_flex_aro servVoyFormText2 noselect">
                                    <p style={{padding: '0 12px', color: frm.image_text_color}}>
                                        <span style={{marginLeft: '15px'}}></span>
                                        {frm.text_2}
                                    </p>
                                </div>
                            )}
                        </div>
                        {window.screen.width > 700 && frm.image_place === 'right' && (
                            <div style={{width: '50%'}}>
                            <div className="row_flex_aro noselect">
                                <img
                                className="formuleImage"
                                src={process.env.REACT_APP_API_IMAGES + frm.image}
                                alt={'formule numéro ' + index + 1} />
                            </div>
                            </div>
                        )}
                        {props.admin.mode && (
                            <>
                                <div
                                    className="servVoyCardadminEdit"
                                    onClick={() => props.setEditFormule({
                                        state: true,
                                        index: index,
                                        formule: frm,
                                        })}>
                                    {icons.edit_icon}
                                </div>
                                <div
                                    className="servVoyCardOnline"
                                    style={{left: '50px'}}
                                    onClick={() => {
                                        updateOnlineFormule(
                                            props.subject,
                                            props.admin,
                                            props.setAdmin,
                                            frm,
                                            props.setFormule
                                        );
                                    }}>
                                    <span
                                        className="noselect"
                                        style={{color: frm.online ? 'blue' : 'red'}}>
                                        {frm.online ? 'En ligne' : 'Hors ligne'}
                                    </span>
                                </div>
                                {!frm.online && (
                                    <div className={window.screen.width > 700
                                        ? "row_flex_bet formuleContent"
                                        : "col_flex_bet formuleContent"}
                                        style={{
                                                background: 'rgba(203, 123, 110, 0.8)',
                                                margin: '0',
                                                position: 'absolute',
                                                top: '0',
                                                left: '0',
                                            }}>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    )}
                </div>
            ))}
        </div>
    )
}
