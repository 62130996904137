import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import icons from '../icons';

export default function Header(props) {
  const [url, setUrl] = useState('');
  const smallMenuMarker = 'linear-gradient(' +
    'rgb(228, 228, 228), white, white, white, white, white, white, rgb(228, 228, 228)' +
  ')';
  const maskItemtools = () => {
    if (document.querySelector('.menuMobilMarker')) {
      document.querySelector('.menuMobilMarker').style.background = 'transparent';
    }
    if (document.querySelector('.serviceMenu')) {
      document.querySelector('.serviceMenu').style.height = '0';
    }
    if(document.querySelector('.plusMenu')) {
      document.querySelector('.plusMenu').style.height = '0';
    }
  };
  const changeDimensionMobilMarker = (a, b) => {
    if (window.scrollY <= 10 && document.querySelector('.menuMobilMarker')) {
      document.querySelector('.menuMobilMarker').style = 
        'left: calc(' + a + ' * var(--menuItemWidth) + ' + b + ' * var(--buttonPaddingMargin))';
    }
  }
  const selectAccueilItem = () => {
    props.setMenu({...props.menu, menu1: 'Accueil', menu2: ''});
    setTimeout(() => changeDimensionMobilMarker(1, 1), 250);
  };
  const selectAProposItem = () => {
    props.setMenu({...props.menu, menu1: 'À propos', menu2: ''});
    setTimeout(() => changeDimensionMobilMarker(2, 3), 250);
  };
  const selectServicesItem = () => {
    props.setMenu({...props.menu, menu1: 'Services', menu2: ''});
    setTimeout(() => changeDimensionMobilMarker(3, 5), 250);
  };
  const selectContactItem = () => {
    props.setMenu({...props.menu, menu1: 'Contact', menu2: ''});
    setTimeout(() => changeDimensionMobilMarker(4, 7), 250);
  };
  const selectPlusItem = () => {
    props.setMenu({...props.menu, menu1: 'Plus', menu2: ''});
    setTimeout(() => changeDimensionMobilMarker(5, 9), 250);
  };

  useEffect(() => {
    if (!props.displayMenu && props.menu.menu1 === 'Services' && document.querySelector('.serviceMenu')) {
        document.querySelector('.serviceMenu').style = 'animation: deployServicesMenu 0.4s linear;'
    }
    if (!props.displayMenu && props.menu.menu1 === 'Plus' && document.querySelector('.plusMenu')) {
        document.querySelector('.plusMenu').style = 'animation: deployPlusMenu 0.4s linear;'
    }
    let localUrl = window.location.href;
    if (localUrl !== url || localStorage.getItem('blvnmt-page') !== props.menu.menu1) {
      if (localUrl.indexOf('A-propos') !== -1) {
        selectAProposItem();
      } else  if (localUrl.indexOf('Services') !== -1) {
        selectServicesItem();
        if (localUrl.indexOf('Mariages') !== -1) {
          props.setMenu({...props.menu, menu2: 'Mariages'});
        }
        if (localUrl.indexOf('Voyages') !== -1) {
          props.setMenu({...props.menu, menu2: 'Voyages'});
        }
        if (localUrl.indexOf('Locations') !== -1) {
          props.setMenu({...props.menu, menu2: 'Locations'});
        }
        if (localUrl.indexOf('Autre-evenement') !== -1) {
          props.setMenu({...props.menu, menu2: 'Autre-evenement'});
        }
      } else if (localUrl.indexOf('Contact') !== -1) {
        selectContactItem();
      } else if (localUrl.indexOf('Plus') !== -1) {
        selectPlusItem();
        if (localUrl.indexOf('Galerie') !== -1) {
          props.setMenu({...props.menu, menu2: 'Galerie'});
        }
        if (localUrl.indexOf('Voyages') !== -1) {
          props.setMenu({...props.menu, menu2: 'Temoignages'});
        }
      } else {
        selectAccueilItem();
      }
      setUrl(localUrl);
      localStorage.setItem('blvnmt-page', props.menu.menu1);
    }

    return ;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 

  return (
    <header className="header row_flex_bet">
      <Link to={'/'} className="logoDiv">
        <div className="noselect">
          <img
            src={process.env.PUBLIC_URL + '/logo_100x100.png'}
            alt="Logo de Belevenement" />
        </div>
      </Link>
      <nav className="menuDiv row_flex_bet">
        {props.screenWidth > 600 && (
          <div className="menuList1 row_flex_bet noselect">
              <span className="menuMobilMarker"></span>
              <Link
                to={'/'}
                className="col_flex_bet menuItem"
                onClick={() => selectAccueilItem()}>
                <span className="menuMarker"></span>
                <span
                  style={{
                    fontWeight:
                      props.menu.menu1 === 'Accueil'
                        ? '800'
                        : 'var(--menuFontWeight)',}}
                  className="menuText">Accueil</span>
              </Link>
              <Link
                to={'/A-propos'}
                className="col_flex_bet menuItem"
                onClick={() => selectAProposItem()}>
                <span className="menuMarker"></span>
                <span
                  style={{fontWeight:
                    props.menu.menu1 === 'À propos'
                      ? '800'
                      : 'var(--menuFontWeight)',}}
                  className="menuText">À propos</span>
              </Link>
              <button
                type="button"
                className="col_flex_bet menuItem"
                onClick={() => {
                  selectServicesItem();
                  if (localStorage.getItem('blvnmt-page') !== props.menu.menu1) {
                    localStorage.setItem('blvnmt-page', 'Services');
                  }
                  }}>
                <span className="menuMarker"></span>
                <span
                  style={{fontWeight: props.menu.menu1 === 'Services' ? '800' : 'var(--menuFontWeight)',}}
                  className="menuText">Services</span>
              </button>
              {!props.displayMenu && props.menu.menu1 === "Services" && props.menu.menu2 === '' && (
                <div className="col_flex_aro serviceMenu">
                  <Link
                    to={'/Services/Voyages'}
                    onClick={() => props.setMenu({...props.menu, menu2: 'Voyage'})}
                    className="secondMenuBut longMenu">Voyage</Link>
                  <Link
                    to={'/Services/Mariages'}
                    onClick={() => props.setMenu({...props.menu, menu2: 'Mariage'})}
                    className="secondMenuBut longMenu">Mariage</Link>
                  <Link
                    to={'/Services/Locations'}
                    onClick={() => props.setMenu({...props.menu, menu2: 'Location'})}
                    className="secondMenuBut longMenu">Location</Link>
                  <Link
                    to={'/Services/Autre-evenement'}
                    onClick={() => props.setMenu({...props.menu, menu2: 'Autre Événement'})}
                    className="secondMenuBut longMenu">Autre Événement</Link>
                </div>
              )}
              <Link to={'/Contact'} className="col_flex_bet menuItem" onClick={() => selectContactItem()}>
                  <span className="menuMarker"></span>
                  <span
                    style={{fontWeight: props.menu.menu1 === 'Contact' ? '800' : 'var(--menuFontWeight)',}}
                    className="menuText">Contact</span>
              </Link>
              <button
                type="button"
                className="col_flex_bet menuItem"
                onClick={() => {
                  selectPlusItem();
                    if (localStorage.getItem('blvnmt-page') !== props.menu.menu1) {
                      localStorage.setItem('blvnmt-page', 'Plus');
                    }
                  }}>
                <span className="menuMarker"></span>
                <span
                  style={{fontWeight: props.menu.menu1 === 'Plus' ? '800' : 'var(--menuFontWeight)',}}
                  className="menuText">Plus</span>
              </button>
              {props.menu.menu1 === "Plus" && props.menu.menu2 === '' && (
                <div className="col_flex_aro plusMenu">
                  <Link
                    to={'/Plus/Galerie'}
                    onClick={() => props.setMenu({...props.menu, menu2: 'Galerie'})}
                    className="secondMenuBut longMenu">Galerie</Link>
                  <Link
                    to={'/Plus/Temoignages'}
                    onClick={() => props.setMenu({...props.menu, menu2: 'Témoignages'})}
                    className="secondMenuBut longMenu">Témoignages</Link>
                </div>
              )}
          </div>
        )}
          <div className="menuList2 row_flex_bet noselect">
            <div
              className="menuResum">{icons.menu_icon}
              {props.displayMenu && (
                <div
                  style={{height: props.menu.menu1 === 'Services'
                    ? '460px'
                    : props.menu.menu1 === 'Plus'
                      ? '440px'
                      : '300px'}}
                  className="col_flex_aro menuSmallScreen">
                  <Link
                    to={'/'}
                    onClick={() => {
                      selectAccueilItem();
                      setTimeout(() => maskItemtools(), 10);
                      props.setDisplayMenu(false);
                    }}
                    style={{
                      background: props.menu.menu1 === 'Accueil' ? smallMenuMarker : 'white',
                      fontWeight: props.menu.menu1 === 'Accueil' ? '800' : 'var(--menuFontWeight)',
                    }}
                    className="col_flex_aro smallMenuItem">Accueil</Link>
                  <Link
                    to={'/A-propos'}
                    onClick={() => {
                      selectAProposItem();
                      setTimeout(() => maskItemtools(), 10);
                      props.setDisplayMenu(false);
                    }}
                    style={{
                      background: props.menu.menu1 === 'À propos' ? smallMenuMarker : 'white',
                      fontWeight: props.menu.menu1 === 'À propos' ? '800' : 'var(--menuFontWeight)',
                    }}
                    className="col_flex_aro smallMenuItem">À propos</Link>
                  <div
                    onClick={() => {
                      if (props.menu.menu1 !== 'Services') {
                        selectServicesItem();
                        setTimeout(() => maskItemtools(), 10);
                      }
                    }}
                    style={{
                      background: props.menu.menu1 === 'Services' ? smallMenuMarker : 'white',
                      fontWeight: props.menu.menu1 === 'Services' ? '800' : 'var(--menuFontWeight)',
                      height: props.menu.menu1 === 'Services' ? '240px' : '40px',
                    }}
                    className="col_flex_aro smallMenuItem">
                    <span>Services</span>
                    {props.menu.menu1 === 'Services' && (
                      <>
                        <Link
                          to={'/Services/Voyages'}
                          type="button"
                          onClick={() => {
                            props.setMenu({...props.menu,  menu2: 'Voyage'});
                            props.setDisplayMenu(false);
                            }}
                          className="secondMenuBut">Voyage</Link>
                        <Link
                          to={'/Services/Mariages'}
                          type="button"
                          onClick={() => {
                            props.setMenu({...props.menu, menu2: 'Mariage'});
                            props.setDisplayMenu(false);
                          }}
                          className="secondMenuBut">Mariage</Link>
                        <Link
                          to={'/Services/Locations'}
                          type="button"
                          onClick={() => {
                            props.setMenu({...props.menu, menu2: 'Location'});
                            props.setDisplayMenu(false);
                          }}
                          className="secondMenuBut">Location</Link>
                        <Link
                          to={'/Services/Autre-evenement'}
                          type="button"
                          onClick={() => {
                            props.setMenu({...props.menu, menu2: 'Autre Événement'});
                            props.setDisplayMenu(false);
                          }}
                          className="secondMenuBut">Autre Événement</Link>
                      </>
                    )}
                  </div>
                  <Link
                    to={'/Contact'}
                    onClick={() => {
                      selectContactItem();
                      setTimeout(() => maskItemtools(), 10);
                      props.setDisplayMenu(false);
                    }}
                    style={{
                      background: props.menu.menu1 === 'Contact' ? smallMenuMarker : 'white',
                      fontWeight: props.menu.menu1 === 'Contact' ? '800' : 'var(--menuFontWeight)',
                    }}
                    className="col_flex_aro smallMenuItem">Contact</Link>
                  <div
                    onClick={() => {
                      if (props.menu.menu1 !== 'Plus') {
                        selectPlusItem();
                        setTimeout(() => maskItemtools(), 10);
                      }
                    }}
                    style={{
                      background: props.menu.menu1 === 'Plus' ? smallMenuMarker : 'white',
                      fontWeight: props.menu.menu1 === 'Plus' ? '800' : 'var(--menuFontWeight)',
                      height: props.menu.menu1 === 'Plus' ? '180px' : '40px',
                    }}
                    className="col_flex_aro smallMenuItem">
                    <span>Plus</span>
                    {props.menu.menu1 === 'Plus' && (
                      <>
                        <Link
                          to={'/Plus/Galerie'}
                          type="button"
                          onClick={() => {
                            props.setMenu({...props.menu, menu2: 'Galerie'});
                            props.setDisplayMenu(false);
                            }}
                          className="secondMenuBut">Galerie</Link>
                        <Link
                          to={'/Plus/Temoignages'}
                          type="button"
                          onClick={() => {
                            props.setMenu({...props.menu, menu2: 'Témoignages'});
                            props.setDisplayMenu(false);
                            }}
                          className="secondMenuBut">Témoignages</Link>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
      </nav>
    </header>
  )
}
