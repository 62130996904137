import axios from 'axios';
    
async function sendConfirmationMail(admin, id) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            comment: 'sendConfirmationMail',
            email: admin.email,
            api_identifier: admin.api_identifier,
            comment_id: id,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            
        } else {
            
        }
    }).catch(() =>  console.log('error'))
}

export default sendConfirmationMail; 