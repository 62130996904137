import React, {useEffect} from 'react';

export default function ManageAProposPara(props) {

    useEffect(() => {
        if (props.newData === null){
            props.setNewData(props.aProposData[props.index]);
        }

        return;
    }, [])
    
    return (
        <div
            className="col_flex_aro apropos_para_text"
            style={{marginTop: '10px'}} >
            <span
                style={{
                    color: 
                        (props.aProposData[props.index].text_2
                            && props.aProposData[props.index].text_2.length) < 1023
                                ? 'blue'
                                : 'red',
                    fontSize: '14px',
                    fontFamily: 'consolas',
                    }}>
                Paragraphe : {
                    props.aProposData[props.index].text_2 && (
                        props.aProposData[props.index].text_2.length
                    )} / 1023 caractéres
            </span>
            <textarea
                className="accueil_para_text setInputFocus"
                cols={window.screen.width > 840 ? 60 : 30}
                rows="6"
                onChange={(e) => {
                    if (e.target.value.length <= 1023) {
                        props.changeParaText_2(e, props.index)
                    }
                }}
                value={props.aProposData[props.index].text_2}></textarea>
        </div>
    )
}
