import React, {useEffect, useState} from 'react';
import icons from '../icons.js';

export default function ArrowUp(props) {
  const [dispArrow, setDispArrow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setDispArrow(window.scrollY > 10 ? true : false);
      if (document.querySelector('.goUpButton')) {
        document.querySelector('.goUpButton').style.top =
          window.location.href.substring(window.location.href.indexOf('#/')) === '#/' 
          ? 'calc(75vh + ' + window.scrollY + 'px)'
          : 'calc(86vh + ' + window.scrollY + 'px)';
      }
      document.querySelector('.goUpButton').style.opacity = '0';
      setTimeout(() => document.querySelector('.goUpButton').style.opacity = '1', 500);
    });

    return;
  }, [])

  return (
    <div
        className="goUpButton"
        onClick={() => {
          document.querySelector("#topApp").scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                  inline: 'nearest'
              });
          }}>
          {dispArrow && (
            <span className="dispArrowUp">{icons.arrow_up_icon}</span>
          )}
      </div>
  )
}
