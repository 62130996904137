import axios from 'axios';
    
async function sendContactMail(emailContent, setMailState) {

    await axios({ 
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            contact: 'sendMail',
            email: emailContent.email,
            firstname: emailContent.firstname,
            lastname: emailContent.lastname,
            contactEmail: emailContent.email,
            eventType: emailContent.eventType,
            comment: emailContent.comment,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setMailState({
                state: true,
                fault: '',
                message: 'Votre message est envoyé, merci.',
            });
            setTimeout(() => setMailState({state: false, fault: '', message: ''}), 2000);
        } else {
            // setAProposData([]);
        }
    }).catch ((e) => console.log('404', e))
}

export default sendContactMail; 