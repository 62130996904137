import React, {useState, useEffect} from 'react';
import PageHeader from './PageHeader';
import Footer from './Footer';
import ArrowUp from './ArrowUp';
// import CommentFormat from './commentComponents/CommentFormat';
import CommentDisplaying from './commentComponents/CommentDisplaying';
// import CommentChooseImage from './commentComponents/CommentChooseImage';
import CommentImageBankManagement from './commentComponents/CommentImageBankManagement';
import CommentEditorFormat1 from './commentComponents/CommentEditorFormat1';
// import CommentEditorFormat2 from './commentComponents/CommentEditorFormat2';
// import CommentEditorFormat3 from './commentComponents/CommentEditorFormat3';
// import CommentEditorFormat3 from './commentComponents/CommentEditorFormat3';
// import CommentEditorFormat4 from './commentComponents/CommentEditorFormat4';
// import CommentBut from './commentComponents/CommentBut.js';
import getCommentData from './request/comment/getCommentData';
// import setOnlineComment from './request/comment/setOnlineComment';
// import sendConfirmationMail from './request/comment/sendConfirmationMail';
// import icons from '../icons.js';
import '../style/commentStyle.css';

export default function PlusTemoignages(props) {
  const [imgBank, setImgBank] = useState(false);
  const [outOfLine, setOutOfLine] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [fond_F2, setFond_F2] = useState([]);
  const [fond_F3, setFond_F3] = useState([]);
  const [commentState, setCommentState] = useState({message: 'en cours'});
  const [changeImg, setChangeImg] = useState(false);
  const [newComment, setNewComment] = useState({
    dispForm: false,
    visu: false,
    error: false,
    title: '',
    comment: '',
    email: '',
    name: '',
    model: '',
    chooseImage: false,
    modelIndex: 0,
    image: 'comment_fond_F2_1.png',
    message: false,
    color: '#110a4e',
    seeModels: false, 
    modelChoosen: 0,
    commentEdit: true,
  });
  const images = [
    ['comment_F1.png', 'Seuleument le commentaire sans image'],
    ['comment_F2.png', 'Votre commentaire sur une image de fond'],
    ['comment_F3.png', 'Votre commentaire à droite, image à gauche'],
    ['comment_F4.png', 'Votre commentaire à gauche, image à droite'],
  ]
  // const chooseImageBut = () => {
  //   let chooseImage = !newComment.chooseImage;
  //   document.querySelector('.formatContainer').style.height = '60px';
  //   document.querySelector('.commentEditContainer').style.height = chooseImage ? '60px' : '600px';
  //   if (document.querySelector('.imageChoiceContainer')) {
  //     document.querySelector('.imageChoiceContainer').style.height =
  //       chooseImage ? '350px' : '60px';
  //   }
  //   setNewComment({
  //     ...newComment,
  //     seeModels: false,
  //     chooseImage: chooseImage,
  //     commentEdit: chooseImage ? false : true,
  //   });
  // }
  // const chooseFormatBut = () => {
  //   let seeModels = !newComment.seeModels;
  //   if (document.querySelector('.imageChoiceContainer')) {
  //     document.querySelector('.imageChoiceContainer').style.height = '60px';
  //   }
  //   document.querySelector('.commentEditContainer').style.height = seeModels ? '60px' : '600px';
  //   document.querySelector('.formatContainer').style.height =
  //     seeModels ? '400px' : '60px';
  //   setNewComment({
  //     ...newComment,
  //     seeModels: seeModels,
  //     chooseImage: false,
  //     commentEdit: seeModels ? false : true,
  //   });
  // }
  // const commentEditorBut = () => {
  //   let commentEdit = !newComment.commentEdit;
  //   document.querySelector('.formatContainer').style.height = '60px';
  //   if (document.querySelector('.imageChoiceContainer')) {
  //     document.querySelector('.imageChoiceContainer').style.height = '60px';
  //   }
  //   document.querySelector('.commentEditContainer').style.height =
  //     commentEdit ? '600px' : '60px';
  //   setNewComment({
  //     ...newComment,
  //     seeModels: false,
  //     chooseImage: false,
  //     commentEdit: commentEdit,
  //   });
  // }
  const getComment = (online) => {
    getCommentData(props.admin, setCommentData, setFond_F2, setFond_F3, online);
  }

  useEffect(() => {
    if (!props.admin.mode) {
      window.scrollTo(0,0);
    }
    if (commentData && commentData.length === 0) {
      getComment(true);
    }

    return;
  }, [])

  return (
    <div className="col_flex_aro dispPage commentPage">
      <PageHeader
          admin={props.admin}
          setAdmin={props.setAdmin}
          changeImg={changeImg}
          setChangeImg={setChangeImg}
          setData={false}
          setFormule={false}
          setNotice={false}
          imageName="img_comment_1_1500x540.png"
          imageId="CommentsTopImg"
          updateOrder="CommentsTopImg"
          pageTitleDisplay={true}
          pageTitle="Témoignages" />      
      
      {!props.admin.mode && (
        <>
          <div
            onClick={() => {setNewComment({...newComment, dispForm: true})}}
            style={{width: '90vw', display: 'flex', justifyContent: 'right'}}>
            <button className="row_flex_aro publishBut">
              <img
                  src={process.env.PUBLIC_URL + '/add_comment_70x70.ico'}
                  alt="Bouton ajouter un commentaire"/>
              <span className="textOnNewPublishBut">Publier un commentaire</span>
            </button>
          </div>
          <p
            className="commentTitle"
            style={{width: '300px'}}>
              Nos clients témoignent</p>
        </>
      )}
      <div>
        {props.admin.mode && (
          <div className="col_flex_aro">
            <button
              className="butStyle_1"
              onClick={() => {
                getComment(outOfLine);
                setOutOfLine(!outOfLine);
              }}
              style={{
                width: '300px',
                background: outOfLine ? '#b18080' : 'lightgreen'}}>
                {!outOfLine
                  ? 'Importez les témoignages hors ligne'
                  : 'Importez les témoignages en ligne'}
            </button>
            <p
              className="commentTitle"
              style={{
                width: '300px',
                textAlign: 'center',
                background: outOfLine ? '#b18080' : 'lightgreen'}}>
                {!outOfLine ? 'En ligne' : 'Hors ligne'}</p>
          </div>
        )}
      </div>
      <hr className="hrStyle" style={{margin: '10px 0'}}></hr>
      
      {commentData && commentData.length > 0 
        ? <CommentDisplaying
            commentData={commentData}
            setCommentData={setCommentData}
            admin={props.admin} />
        : <p>Pas de commentaire</p>
      }



      {commentState.message === 'en cours' && newComment.dispForm && (
        <div
          className="modalContainer row_flex_aro dispCommentForm"
          style={{
            background: 'rgba(120, 120, 120, 0.7)',
            }}>
          <div
            className="modalContent col_flex_aro"
            style={{
              position: 'relative',
              overflow: 'auto',
              background: 'linear-gradient(lightgrey, white, lightgrey)',
              minHeight: '98vh',
              }}>
            <div style={{position: 'absolute', top: '10px', right: '20px'}}>
              <button
                className="butStyle_1"
                style={{
                  background: 'orange',
                  width: '100px',
                  height: '30px',
                  fontSize: '12px'}}
                onClick={() => {
                  setNewComment({
                    ...newComment,
                    dispForm: false,
                    seeModels: false,
                    chooseImage: false,
                  })}}>
                Retour aux témoignages
              </button>
            </div>


            {/* <div 
              className="noselect formatContainer"
              style={{
                width: '90%',
                overflow: 'hidden',
              }}>
              <hr style={{color: 'grey'}}></hr>
              <CommentBut
                newComment={newComment}
                setNewComment={setNewComment}
                chooseFunction={chooseFormatBut}
                caretInversor={newComment.seeModels}
                title={window.screen.width > 500
                  ? "Choisir une présentation"
                  : "Présentation"} />
              {newComment.seeModels && ( 
                <CommentFormat 
                    newComment={newComment}
                    setNewComment={setNewComment}
                    fond_F2={fond_F2}
                    fond_F3={fond_F3}
                    images={images} />
              )}
              <hr style={{color: 'grey'}}></hr>
            </div> */}

            {/* {newComment.modelChoosen !== 0 && (
              <div 
                className="noselect imageChoiceContainer"
                style={{
                  width: '90%',
                  overflow: 'hidden',
                }}>
                <hr style={{color: 'grey'}}></hr>
                <CommentBut
                  newComment={newComment}
                  setNewComment={setNewComment}
                  chooseFunction={chooseImageBut}
                  caretInversor={newComment.chooseImage}
                  title="Choisir une image" />
                {newComment.chooseImage && (
                  <CommentChooseImage
                    fond_F2={fond_F2}
                    fond_F3={fond_F3}
                    newComment={newComment}
                    setNewComment={setNewComment}
                    images={images} />
                )}
                <hr style={{color: 'grey'}}></hr>
              </div>
            )} */}


            <div 
              className="noselect commentEditContainer"
              style={{
                width: '90%',
                overflow: 'hidden',
                height: '600px',
              }}>
              <hr style={{color: 'grey'}}></hr>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
                {/* <CommentBut
                  newComment={newComment}
                  setNewComment={setNewComment}
                  chooseFunction={commentEditorBut}
                  caretInversor={newComment.commentEdit}
                  title={window.screen.width > 650
                  ? "Rédigez un commentaire"
                  : "Commentaire"} /> */}
                {newComment.commentEdit && (
                  <div style={{marginLeft: window.screen.width > 700 ? '100px' : '30px'}}>
                      <label className="commentInputLabel">Couleur des textes</label>
                      <input
                        type="color"
                        className="commentColorText"
                        onChange={(e) => setNewComment({...newComment, color: e.target.value})}
                        value={newComment.color} />
                  </div>
                )}
              </div>
              {newComment.modelChoosen === 0 && newComment.commentEdit && (
                <CommentEditorFormat1
                  newComment={newComment}
                  setNewComment={setNewComment}
                  commentState={commentState}
                  setCommentState={setCommentState}
                  images={images} />
              )}
              {/* {newComment.modelChoosen === 1 && newComment.commentEdit && (
                <CommentEditorFormat2
                  newComment={newComment}
                  setNewComment={setNewComment}
                  commentState={commentState}
                  setCommentState={setCommentState}
                  images={images} />
              )}
              {newComment.modelChoosen > 1 && newComment.commentEdit && (
                <CommentEditorFormat3
                  newComment={newComment}
                  setNewComment={setNewComment}
                  commentState={commentState}
                  setCommentState={setCommentState}
                  images={images} />
              )} */}
            </div>


          </div>
        </div>
      )}
      {/* <hr className="hrStyle"></hr> */}
      {commentState.message === 'Votre commentaire est soumis au modérateur.' && (
        <div className="modalContainer">
          <div
            className="row_flex_aro modalContent"
            style={{background: 'transparent'}}>
            <p className="confirmationMessage">{commentState.message}</p>
          </div>
        </div>
      )}
      {imgBank && (
        <CommentImageBankManagement
          admin={props.admin}
          setImgBank={setImgBank}
          fond_F2={fond_F2}
          setFond_F2={setFond_F2} 
          fond_F3={fond_F3}
          setFond_F3={setFond_F3} />
      )}
      <Footer />
      <ArrowUp />


      {/* <div
        className="row_flex_aro"
        style={{
          width: '30vw', height: '90vh',
          zIndex: '5000',
          position: 'absolute', top: '20vh', left: '35vw',
          }}>
        <img
          src="https://clipartmag.com/images/website-under-construction-image-6.gif"
          alt="Website under construction"/>
      </div> */}


    </div>
  )
}
