import React, {useState} from 'react';
import setNewComment from '../request/comment/setNewComment';

export default function CommentValidation(props) {
    const [emailForm, setEmailForm] = useState({
        display: false,
        validation: false,
    });
    return (
        <div className={window.screen.width > 650
                        ? "row_flex_aro inputCommentImage"
                        : "col_flex_aro inputCommentImage"}>
            {props.newComment.visu && (
                <button
                    className="butStyle_1 animCommentBackBut"
                    style={{
                        width: '250px',
                        background: 'orange',
                        margin: '0 10px',
                    }}
                    name="backToComment"
                    onClick={() => {
                        props.setNewComment({...props.newComment, visu: false})
                    }}>
                    Retour mode édition
                </button>
            )}
            <button
                className="butStyle_1"
                name="commentTitle"
                style={{
                    background: 'lightgreen',
                    width: '260px',
                    height: '30px',
                    }}
                onClick={() => {
                    if (!props.newComment.visu && props.newComment.comment !== '') {
                        props.setNewComment({...props.newComment, visu: true});
                    } else if (!props.newComment.visu && props.newComment.comment === '') {
                        props.setNewComment({...props.newComment, error: true})
                    } else if (props.newComment.visu) {
                        if (emailForm.validation) {
                            setNewComment(props.newComment, props.setCommentState, props.setNewComment);
                        } else {
                            setEmailForm({...emailForm, display: true});
                        }
                    }
                }} >
                <span>
                {props.newComment.visu  
                    ? 'Publier mon témoignage'
                    : 'Prévisualiser mon témoignage'}
                </span>
            </button>
            {emailForm.display && (
                <div className="modalContainer col_flex_aro">
                    <div
                        className="col_flex_aro modalContent"
                        style={{
                            maxHeight: '240px',
                            maxWidth: '500px',
                            background: 'linear-gradient(lightgrey, white, lightgrey)',
                            }}>
                        <label
                            htmlFor="commentEmail"
                            className="commentInputLabel"
                            style={{width: '100%', textAlign: 'center'}} >
                                <span
                                    id="commentEmailFormTitle"
                                    style={{fontSize: '16px'}}>
                                    Le modérateur souhaite connaître votre email
                                </span> <br/>
                                <span style={{fontSize: '16px'}}>
                                    Vous serez informé de la mise <br/>
                                    en ligne de votre témoignage
                                </span>
                        </label>
                        <input
                            type="email"
                            name="commentEmail"
                            id="commentEmail"
                            className="commentInputStyle"
                            style={{minWidth: '280px', maxWidth: '280px'}}
                            placeholder="Votre email svp"
                            onChange={(e) => {
                                props.setNewComment({...props.newComment, email: e.target.value});
                            }}
                            value={props.newComment.email} />
                        <div className="row_flex_aro" style={{width: '100%'}}>
                            <button
                                type="button"
                                className="butStyle_1"
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    background: 'transparent',
                                    color: 'red'
                                }}
                                onClick={() => setEmailForm({validation: true, display: false})} >
                                    Passer cette étape</button>
                            <button
                                type="button"
                                className="butStyle_1"
                                style={{
                                    background: 'lightgreen',
                                }}
                                onClick={() => {
                                    if (props.newComment.email !== ''
                                        && props.newComment.email.indexOf('@') > -1
                                        && props.newComment.email.indexOf('.') > -1) {
                                            setEmailForm({validation: true, display: false});
                                        } else {
                                            document.querySelector('#commentEmailFormTitle').innerText =
                                                'Email incorrect';
                                            document.querySelector('#commentEmailFormTitle').style.color =
                                                'red';
                                            document.querySelector('#commentEmailFormTitle').style.fontSize =
                                                '20px';
                                        }
                                    }} >
                                    OK</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
