import React from 'react'

export default function MentionsLegales(props) {
    return (
        <div className="col_flex_aro legalContainer" style={{width: '90vw'}}>
            <div className="row_flex_aro">
                <h2 className="legalTitle">Mentions légales</h2>
                <div>
                    <button
                    className="closeButDiv"
                    onClick={() => props.setDispMentLeg(false)}
                    style={{
                        position: 'fixed',
                        top: '100px',
                        right: '5vw',
                        transform: 'scale(0.7)',
                    }}>X</button>
                </div>
            </div>
            <div className="legalPara">
                <h3>1. Éditeur du site</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Le site <span style={{color: 'blue'}}>www.belevenement.com</span> est édité par :
                </p>
                <p style={{margin: '5px'}}>
                    Bel Événement
                </p>
                <p style={{margin: '5px'}}>
                    883 route d'Olmet - 34700 Lodève
                </p>
                <p style={{margin: '5px'}}>
                    07 82 10 26 06
                </p>
                <p style={{margin: '5px', color: 'blue'}}>
                    br.belevenement@hotmail.com
                </p>
                <p style={{margin: '5px'}}>
                    Numéro SIRET : en cours
                </p>
                <p style={{margin: '5px'}}>
                    TVA intracommunautaire : en cours
                </p>
                <h3>2. Directeur de la publication</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Le directeur de la publication est : Rodriguez Belén
                </p>
                <h3>3. Hébergement</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Le site est hébergé par : OVH
                </p>
                <h3>4. Propriété intellectuelle</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Tous les éléments présents sur le site, y compris, mais sans s'y limiter, 
                    les textes, images, logos, graphiques, vidéos, sont la propriété de Bel Événement 
                    ou de ses partenaires. Toute reproduction, distribution ou exploitation de ces 
                    contenus sans autorisation préalable est interdite.
                </p>
                <h3>5. Protection des données personnelles</h3>
                <p>
                    <span style={{marginLeft: '30px'}}></span>
                    Conformément à la réglementation en vigueur, vous disposez de droits d'accès, 
                    de rectification, d'effacement et d'opposition concernant vos données personnelles. 
                    Pour plus d'informations, veuillez consulter notre politique de confidentialité.
                </p>
            </div>
            <div style={{marginBottom: '200px'}}></div>
        </div>
      )
}
