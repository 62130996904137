import axios from 'axios';
    
async function updateFormuleImage(
        subject,
        file,
        admin,
        setAdmin,
        setFormule,
        editFormule,
        setEditFormule,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            services: subject,
            email: admin.email,
            api_identifier: admin.api_identifier,
            frm_id: editFormule.id,
            fileUpload: file,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setFormule(resp.data.Formules);
            if (subject === 'updateVoyagesFormuleImage') {
                // setFormule(resp.data.voyagesArticlesFormules);
                resp.data.Formules.map((frm, index) => {
                    if (frm.id === editFormule.id) {
                        setEditFormule({...editFormule, state: true, formule: frm});
                    }
                })
            }
            if (subject === 'updateMariagesFormuleImage') {
                // setFormule(resp.data.mariagesArticlesFormules);
                resp.data.Formules.map((frm, index) => {
                    if (frm.id === editFormule.id) {
                        setEditFormule({...editFormule, state: true, formule: frm});
                    }
                })
            }
            if (subject === 'updateLocationsFormuleImage') {
                // setFormule(resp.data.locationsArticlesFormules);
                resp.data.Formules.map((frm, index) => {
                    if (frm.id === editFormule.id) {
                        setEditFormule({...editFormule, state: true, formule: frm});
                    }
                })
            }
            if (subject === 'updateAutresFormuleImage') {
                // setFormule(resp.data.autresArticlesFormules);
                resp.data.Formules.map((frm, index) => {
                    if (frm.id === editFormule.id) {
                        setEditFormule({...editFormule, state: true, formule: frm});
                    }
                })
            }
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })}
    )
}

export default updateFormuleImage;