const resumNavBar = () => {
  document.querySelector('.menuResum').classList = 'menuResum expandResum';
  if (document.querySelector('.menuMobilMarker')) {
    document.querySelector('.menuMobilMarker').style.background = 'transparent';
  }
  if(document.querySelector('.plusMenu')) {
    document.querySelector('.plusMenu').style.height = '2px';
  }
  if (document.querySelector('.serviceMenu')) {
    document.querySelector('.serviceMenu').style.height = '2px';
  }
  if(document.querySelector('.menuList1')) {
    document.querySelector('.menuList1').style = 'z-index: -1';
  }
  if(document.querySelector('.menuList2')) {
    document.querySelector('.menuList2').style = 'z-index: 200';
  }
  let items = document.querySelectorAll('.menuItem');
  items.forEach(item => {
    item.classList = 'col_flex_bet menuItem reducItem';
  });
  setTimeout(
    () => {
      items.forEach(item => {
        item.style = 'transform: scaleY(0)';
      });
      document.querySelector('.menuResum').style = 'transform: scale(1)';
    },
    400,
  );
};
export default resumNavBar;