import axios from 'axios';
    
async function updateTopImg(
        file,
        admin,
        setAdmin,
        setData,
        setNotice,
        setFormule,
        setChangeImg,
        updateOrder,
        imageName,
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            updateTopImage: updateOrder, // 'updateTopImg',
            email: admin.email,
            api_identifier: admin.api_identifier,
            imgFileName: imageName,
            fileUpload: file,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            if (resp.data.Notices) {setNotice(resp.data.Notices);}
            if (resp.data.Formules) {setFormule(resp.data.Formules);}
            if (resp.data.Data) {setData(resp.data.Data);}
            setChangeImg(false);
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
        }
    }).catch((e) => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        })}
    )
}

export default updateTopImg;