import axios from 'axios';
    
async function setNewUser(
        admin,
        setAdmin,
        newUser,
        setNewUser,
        setUsers
    ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {},
        data: {
            user: 'setNew',
            api_identifier: admin.api_identifier,
            email: admin.email,
            newEmail: newUser.email,
            password: newUser.pwd,
            is_verified: newUser.is_verified,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_JWT
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            setNewUser({
                dispNewUserForm: false,
                email: '',
                pwd: '',
                is_verified: '',
            });
            setUsers(resp.data.users);
        } else {
            setAdmin({
                ...admin,
                mode: false,
                pwd: "",
                api_identifier: '',
                seePwd: false,
                message: 'Erreur d\'identifiants ou de réseau',
            });
            setNewUser({
                dispNewUserForm: false,
                email: '',
                pwd: '',
                is_verified: '',
            });
        }
    }).catch(() => {
        setAdmin({
            ...admin,
            mode: false,
            pwd: "",
            api_identifier: '',
            seePwd: false,
            message: 'Erreur d\'identifiants ou de réseau',
        });
        setNewUser({
            dispNewUserForm: false,
            email: '',
            pwd: '',
            is_verified: '',
        });
    })
}

export default setNewUser;