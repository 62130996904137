import React, {useState, useEffect} from 'react';
import ParaWithoutImage from './accueilComponents/ParaWithoutImage';
import ParaWithImageOnLeft from './accueilComponents/ParaWithImageOnLeft';
import ParaWithImageOnRight from './accueilComponents/ParaWithImageOnRight';
import ArrowUp from './ArrowUp';
import Footer from './Footer';
import {Link} from "react-router-dom";
import getAccueilData from './request/accueil/getAccueilData';
import createAccueilPara from './request/accueil/createAccueilPara';
import restoreOldAccueilPara from './request/accueil/restoreOldAccueilPara';
import updateAccueilTopImg from './request/accueil/updateAccueilTopImg';
import icons from '../icons.js';
import '../style/accueilStyle.css';

export default function Accueil(props) {
  const [accueilData, setAccueilData] = useState([]);
  const [oldAccueilData, setOldAccueilData] = useState([]);
  const [markUpdating, setMarkUpdating] = useState({state: false, id: null});
  const [changeImg, setChangeImg] = useState(false);
  
  useEffect(() => {
    if (!props.admin.mode) {
      window.scrollTo(0,0);
    }
    getAccueilData(props.admin, setAccueilData, setOldAccueilData);

    return;
  }, [])

  return (
    <div className="col_flex_aro accueil_page dispPage" id="accueiPageEffect">
      <div className="accueil_img1_div" style={{marginBottom: '60px'}}>
        <div className="noselect">
          <img
            className="accueil_img1"
            id="accueilTopImg"
            src={process.env.REACT_APP_API_IMAGES + "img_accueil_1_1500x540.png"}
            alt="Décor de l'entête de la page accueil" />
          {props.admin.mode && (
            <div
              style={{
                  zIndex: '3000',
                  display: 'flex',
                  flexDirection: 'row',
                  borderLeft: '1px solid black',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}>
              <span
                onClick={() => setChangeImg(!changeImg)}
                style={{
                    padding: '0 10px 5px 10px',
                    animation: 'showEditIcon 2s linear infinite',
                    }}>
                {icons.edit_icon}
              </span>
              {changeImg && (
                <>
                  <label
                    style={{zIndex: '3000', fontSize: '18px', fontWeight: '600', margin: '4px 0 0 15px'}}
                    htmlFor="changeAccueilTopImg">
                    Changer l'image ci-dessus (1500px / 540px)
                  </label>
                  <input
                    type="file"
                    id="changeAccueilTopImg"
                    className="fileInputBut"
                    style={{zIndex: '5000', margin: '6px 0 0 15px', height: '20px'}}
                    onChange={(e) => {
                      updateAccueilTopImg(
                        e.target.files[0],
                        props.admin,
                        props.setAdmin,
                        setAccueilData,
                        setChangeImg,
                      );
                      setTimeout(() => 
                        document.querySelector('#accueilTopImg').src =
                          process.env.REACT_APP_API_IMAGES +
                            "img_accueil_1_1500x540.png?" +
                            Date.now(),
                        2000,
                      );
                      }} />
                  </>
              )}
            </div>
          )}
        </div>
        <div className="col_flex_aro accueil_text1_div noselect">
          <div className="col_flex_aro accueil_text1">
            <span>BIENVENUE CHEZ</span>
            <span><i>BEL ÉVÉNEMENT</i></span>
          </div>
          <p className="accueil_text3">
            <span style={{borderBottom: '2px solid white', position: 'relative', top: '-1vw'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span style={{padding: '0 5px'}}>
              <span
                onClick={() => props.setAdmin({...props.admin, dispPwdInput: true })}>
                O
              </span>rganisation d'événements sur-mesure
            </span>
            <span style={{borderBottom: '2px solid white', position: 'relative', top: '-1vw'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>
        </div>
      </div>
      {accueilData && accueilData.length === 0 && (
        <div className="row_flex_aro">
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
        </div>
      )}
      {accueilData && accueilData.length > 0 && accueilData.map((dt, index) => (
        <div key={"acc" + index}>
          {dt.image_place === 'none' && (
            <ParaWithoutImage
              dt={dt}
              index={index}
              admin={props.admin}
              setAdmin={props.setAdmin}
              markUpdating={markUpdating}
              setMarkUpdating={setMarkUpdating}
              accueilData={accueilData}
              setAccueilData={setAccueilData}
              setOldAccueilData={setOldAccueilData} />
          )}
          {dt.image_place === 'left' && (
            <ParaWithImageOnLeft
              dt={dt}
              index={index}
              admin={props.admin}
              setAdmin={props.setAdmin}
              markUpdating={markUpdating}
              setMarkUpdating={setMarkUpdating}
              accueilData={accueilData}
              setAccueilData={setAccueilData}
              setOldAccueilData={setOldAccueilData} />
          )}
          {dt.image_place === 'right' && (
            <ParaWithImageOnRight
              dt={dt}
              index={index}
              admin={props.admin}
              setAdmin={props.setAdmin}
              markUpdating={markUpdating}
              setMarkUpdating={setMarkUpdating}
              accueilData={accueilData}
              setAccueilData={setAccueilData}
              setOldAccueilData={setOldAccueilData} />
          )}
        </div>
      ))}
      {props.admin.mode && (
        <>
          <div>
            <button
              style={{fontSize: '14px', background: 'magenta', margin: '5px 0 50px 0'}}
              className="butStyle_1"
              onClick={() => createAccueilPara(
                  props.admin,
                  props.setAdmin,
                  setAccueilData,
                )}>Ajouter un article</button>
          </div>
          <div style={{position: 'relative'}}>
            <hr className="hrStyle" style={{border: '1px solid lightblue'}}></hr>
            <span
              style={{
                position: 'absolute',
                top: '8px',
                left: '10vw',
                padding: '2px 5px',
                background: 'lightblue',
                borderRadius: '10px',
                fontSize: '16px',
                fontWeight: '600',
                }}>
              {oldAccueilData && <>{'Ci-dessous les ' + oldAccueilData.length + ' articles suprimés'}</>}
            </span>
          </div>
          {oldAccueilData && oldAccueilData.map((dt, index) => (
            <div
              key={'oldArticle' + index}
              className="col_flex_aro"
              style={{
                  border: '5px solid lightblue',
                  maxWidth: '80vw',
                  padding: '15px',
                  marginBottom: '10px',
                  position: 'relative',
                }}>
              <button
                className="butStyle_1"
                style={{
                    background: 'yellow',
                    position: 'absolute',
                    top: '0',
                    right: '-30px',
                  }}
                onClick={() => restoreOldAccueilPara(
                      props.admin,
                      props.setAdmin,
                      setOldAccueilData,
                      setAccueilData,
                      dt.id,
                  )}
                >
                  Restaurer
              </button>
              {dt.image !== '' && (
                <img
                  src={process.env.REACT_APP_API_IMAGES + dt.image}
                  width="200"
                  height="160"
                  alt={"Old article num " + index + 1}/>
              )}
              <div style={{fontSize: '16px', fontWeight: '600', marginBottom: '15px'}}>{dt.title}</div>
              <div style={{fontSize: '14px', fontWeight: '500', marginBottom: '15px'}}>{dt.text_1}</div>
              <div style={{fontSize: '14px', fontWeight: '500'}}>{dt.text_2}</div>
            </div>
          ))}
        </>
      )}
      <div className="row_flex_aro" style={{flexFlow: 'wrap'}}>
        <div className="row_flex_aro">
          <div
            className="clikableIcon"
            onMouseOver={() => {
              document.querySelector('#markVoyage').style.opacity = '1';
              document.querySelector('#markVoyage').style.top = '45px';
              }}
            onMouseLeave={() => {
                document.querySelector('#markVoyage').style.opacity = '0';
                document.querySelector('#markVoyage').style.top = '-15px';
              }}>
            <Link
              to={'/Services/Voyages'}
              style={{textDecoration: 'none'}}
              onClick={() => props.setMenu({...props.menu, menu2: 'Voyage'})}>
              <img
                src={process.env.REACT_APP_API_IMAGES + '/voyageIcon.png'}
                alt="Lien vers la page voyages"/>
              <span
                className="textOnClikableIcon"
                style={{left: '12px'}}
                id="markVoyage">Voyages</span>
            </Link>
          </div>
          <div
            className="clikableIcon"
            onMouseOver={() => {
              document.querySelector('#markMariage').style.opacity = '1';
              document.querySelector('#markMariage').style.top = '45px';
              document.querySelector('#imgOnlikableIcon2').style.transform = 
                'scale(0.4) translateX(-50px) translateY(-80px)';
              }}
            onMouseLeave={() => {
              document.querySelector('#markMariage').style.opacity = '0';
              document.querySelector('#markMariage').style.top = '-15px';
              document.querySelector('#imgOnlikableIcon2').style.transform = 
                'scale(1) translateX(0) translateY(0)';
              }}>
            <Link
              to={'/Services/Mariages'}
              style={{textDecoration: 'none'}}
              onClick={() => props.setMenu({...props.menu, menu2: 'Mariage'})}>
              <img
                id="imgOnlikableIcon2"
                src={process.env.REACT_APP_API_IMAGES + '/mariageIcon.png'}
                alt="Lien vers la page mariages"/>
              <span
                className="textOnClikableIcon"
                style={{left: '8px'}}
                id="markMariage">Mariages</span>
            </Link>
          </div>
        </div>
        <div className="row_flex_aro">
          <div
            className="clikableIcon"
            onMouseOver={() => {
              document.querySelector('#markLocation').style.opacity = '1';
              // document.querySelector('#markLocation').style.top = '45px';
              document.querySelector('#markLocation').style.transform = 'rotateY(0)';
              document.querySelector('#imgOnlikableIcon3').style.transform = 'scale(0)';
              }}
            onMouseLeave={() => {
              document.querySelector('#markLocation').style.opacity = '0';
              // document.querySelector('#markLocation').style.top = '45px';
              document.querySelector('#markLocation').style.transform = 'rotateY(90deg)';
              document.querySelector('#imgOnlikableIcon3').style.transform = 'scale(1)';
              }}>
            <Link
              to={'/Services/Locations'}
              style={{textDecoration: 'none'}}
              onClick={() => props.setMenu({...props.menu, menu2: 'Location'})}>
              <img
                id="imgOnlikableIcon3"
                src={process.env.REACT_APP_API_IMAGES + '/locationIcon.png'}
                alt="Lien vers la page locations"/>
              <span
                className="textOnClikableIcon"
                style={{left: '6px', top: '45px'}}
                id="markLocation">Locations</span>
            </Link>
          </div>
          <div
            className="clikableIcon"
            onMouseOver={() => {
              document.querySelector('#markAutre').style.opacity = '1';
              // document.querySelector('#markAutre').style.top = '45px';
              document.querySelector('#markAutre').style.transform = 
                'rotateY(0)';
              document.querySelector('#imgOnlikableIcon4').style.transform = 
                'rotateY(180deg)';
              document.querySelector('#imgOnlikableIcon4').style.opacity = '0';
              }}
            onMouseLeave={() => {
              document.querySelector('#markAutre').style.opacity = '0';
              // document.querySelector('#markAutre').style.top = '-15px';
              document.querySelector('#markAutre').style.transform = 
                'rotateY(180deg)';
              document.querySelector('#imgOnlikableIcon4').style.transform = 
                'rotateY(0)';
                document.querySelector('#imgOnlikableIcon4').style.opacity = '1';
              }}>
            <Link
              to={'/Services/Autre-evenement'}
              style={{textDecoration: 'none'}}
              onClick={() => props.setMenu({...props.menu, menu2: 'Autre Événement'})}>
            <img
                id="imgOnlikableIcon4"
                src={process.env.REACT_APP_API_IMAGES + '/autreIcon.png'}
                alt="Lien vers la page autres-événements"/>
              <span
                className="textOnClikableIcon"
                style={{left: '20px', top: '45px'}}
                id="markAutre">Autres</span>
            </Link>
          </div>
        </div>
      </div>
      <hr className="hrStyle" style={{marginTop: '50px'}}></hr>
      <div
        className="row_flex_aro"
        style={{position: 'relative', marginBottom: '20px'}}>
        <Link
          to={'/Contact'}
          style={{textDecoration: 'none'}}
          onClick={() => props.setMenu({...props.menu, menu2: 'Contact'})}>
          <img
            className="clikableImage"
            src={process.env.REACT_APP_API_IMAGES + '/contactImage.png'}
            alt="Lien vers la page autres-événements"/>
          <div
            className="textOnImage"
            id="markContact">Contact</div>
        </Link>
      </div>
      <hr className="hrStyle"></hr>
      <Footer />
      <ArrowUp offset="10vh"/>
    </div>
  )
}
