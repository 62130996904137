import React, {useEffect} from 'react';

export default function DisplayImage(props) {
    useEffect(() => {
        if (props.newData === null) {
            props.setNewData(props.aProposData[props.index])
        }

        return;
    }, [props.newData])
    return (
        <div className="noselect" style={{position: 'relative'}}>
            <div
                className={
                    props.aProposData[props.index].image_place === 'left'
                        ? props.admin.mode ? "bgTopAdminLeft" : "bgTopLeft"
                        : props.admin.mode ? "bgTopAdminRight" : "bgTopRight"
                    }
                style={{
                    '--articleImgBg': props.aProposData[props.index].image_bg_color,
                    transform: 
                    (props.admin.mode
                        && props.aProposData[props.index].image_place === 'right')
                            ? 'translateX(70px)'
                            : 'translateX(0)',
                    }}>
            </div>
            <div>
            <img
                className={props.admin.mode ? "bgAdminImage" : "bgImage"}
                style={
                    props.aProposData[props.index].image_place === 'left'
                        ? {left: 'var(--articleImgBgGap)'}
                        : {right:
                            props.admin.mode
                                ? 'var(--articleImgBgGap)'
                                : 'var(--articleImgBgGap)'}
                    }
                src={process.env.REACT_APP_API_IMAGES + props.aProposData[props.index].image}
                alt="A propos, article 1"/>
            </div>
            {props.admin.mode && (
                <div className="aProposImageForm">
                    <p className="row_flex_aro aProposLabelTp1">
                        De quel coté l'image?
                    </p>
                    <div className="row_flex_aro" style={{background: 'lightgrey'}}>
                        <label className="aProposLabel">A gauche</label>
                        <input
                            type="radio"
                            name={props.aProposData[props.index].id + "aPropos_image_place"}
                            id={props.aProposData[props.index].id + "aPropos_image_place_left"}
                            value="left"
                            onChange={(e) => props.onChangeImagePlace(e, props.index)}
                            checked={props.aProposData[props.index].image_place === 'left'} />
                        <input
                            type="radio"
                            name={props.aProposData[props.index].id + "aPropos_image_place"}
                            id={props.aProposData[props.index].id + "aPropos_image_place_right"}
                            value="right"
                            onChange={(e) => props.onChangeImagePlace(e, props.index)}
                            checked={props.aProposData[props.index].image_place === 'right'} />
                        <label className="aProposLabel">A droite</label>
                    </div>
                    <hr></hr>
                    <div className="row_flex_aro">
                        <p className="row_flex_aro aProposLabelTp2">
                            Couleur de l'arriére plan?
                        </p>
                        <input
                            type="color"
                            style={{margin: '2px 10px 0 10px'}}
                            onChange={(e) => props.changeParaImageBgColor(e, props.index)}
                            value={props.aProposData[props.index].image_bg_color } />
                    </div>
                    <hr></hr>
                    <div className="col_flex_aro">
                        <p className="row_flex_aro aProposLabelTp2">
                            Changer l'image? ( 500px / 360 px)
                        </p>
                        <input
                            type="file"
                            className="fileInputBut"
                            style={{margin: '10px 10px 0 10px'}}
                            onChange={(e) => props.onChangeAProposImage(e, props.index)} />
                    </div>
                </div>
            )}
        </div>
    )
}
